<!--Start Back To Top Button--> 
<a href="#" (click)="$event.preventDefault()" class="back-to-top"><i class='bx bxs-up-arrow-alt'></i></a>
<!--End Back To Top Button-->

<!--Start footer -->
<footer class="page-footer">
    <p class="mb-0">{{data.TITLES.COPYRIGHT}} {{dateNow | date: 'yyyy'}}. {{data.TITLES.ALL_RIGHTS_RESERVED}}
        <ng-container *ngIf="this.authService.getUser.isRoot == true">
            <b>(SUPER ADMIN)</b>
        </ng-container>
    </p>
</footer>
<!--End footer -->
