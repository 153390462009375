import { INTERNAL_ROUTES } from "@data/constants/routes";

export const MODULE_CONSTANTS = {
    TITLES: {
        MODULE: 'Modulo',
        MODULE_LIST: 'Listado Modulos',
        MODULE_NEW: 'Crear Modulo',
        SUBMODULE: 'Submodulo',
        SUBMODULE_LIST: 'Listado Submodulos',
        SUBMODULE_NEW: 'Crear Submodulo'
    },
    MODULE_LIST: {
        TITLE: 'Modulo',
        SEARCH_MODULES: 'Buscar Modulos',
        TH_NAME: 'Nombre',
        TH_DESCRIPTION: 'Descripción',
        TH_ACTIONS: 'Acciones',
        TH_STATUS: 'Estado',
        MSG_ACTIVE: 'Activo',
        MSG_INACTIVE: 'Inactivo',
        MSG_ACTIVATE: 'Activar',
        MSG_INACTIVATE: 'Inactivar',
        MSG_ASSIGN_SUBMODULES: 'Asignar Submodulos',
        MSG_EDIT: 'Editar',
        MSG_LIST_SUBMODULES: 'Consultar Submodulos',
        MSG_ALERT_ACTIVATE_MODULE : '¿Esta seguro de Activar el modulo ## ?',
        MSG_ALERT_INACTIVATE_MODULE : '¿Esta seguro de Inactivar el modulo ## ?',
        UPDATE_MODULE_TITLE: 'Actualización Estado',
        UPDATE_MODULE_MSG: 'Estado Actualizado Exitosamente para el Modulo ',
        BUTTON_NEW: 'Nuevo',
        URL_MODULE_NEW: INTERNAL_ROUTES.MODULE_NEW,        
        URL_MODULE_EDIT: INTERNAL_ROUTES.MODULE_EDIT,
        URL_SUBMODULE_LIST: INTERNAL_ROUTES.SUBMODULE_LIST,
    },
    MODULE_NEW_EDIT:{
        TH_NAME: 'Nombre(*)',
        TH_DESCRIPTION: 'Descripción(*)',
        IS_MODULE_PARENT: 'Es Submodulo?',
        PARENT:'Modulo Padre',
        CHILD: 'Submodulo',
        PATH: 'Ruta',
        BUTTON_CANCEL: 'Cancelar',
        BUTTON_REGISTER: 'Registrar',
        BUTTON_UPDATE: 'Actualizar',
        BUTTON_BACK: 'Regresar',
        MSG_REGISTER_MODULE_TITLE: 'Registrar Modulo',
        MSG_REGISTER_MODULE_DESC: 'Se Registro Exitosamente el Modulo ',
        MSG_UPDATE_MODULE_TITLE: 'Actualizar Modulo',
        MSG_UPDATE_MODULE_DESC: 'Se Actualizo Exitosamente el Modulo ',
        MSG_GET_MODULE_TITLE: 'Información Modulo',
        MSG_GET_MODULE_DESC: 'No se encontro información para el Modulo',
        MSG_REGISTER_SUBMODULE_TITLE: 'Registrar Submodulo',
        MSG_REGISTER_SUBMODULE_DESC: 'Se Registro Exitosamente el Submodulo ',
        MSG_UPDATE_SUBMODULE_TITLE: 'Actualizar Submodulo',
        MSG_UPDATE_SUBMODULE_DESC: 'Se Actualizo Exitosamente el Submodulo ',
        MSG_GET_SUBMODULE_TITLE: 'Información Submodulo',
        MSG_GET_SUBMODULE_DESC: 'No se encontro información para el Submodulo',
    },
    SUBMODULE_LIST: {
        TITLE: 'Submodulos',
        SEARCH_SUBMODULES: 'Buscar Submodulos',
        TH_NAME: 'Nombre',
        TH_DESCRIPTION: 'Descripción',
        TH_ACTIONS: 'Acciones',
        TH_STATUS: 'Estado',
        MSG_ACTIVE: 'Activo',
        MSG_INACTIVE: 'Inactivo',
        MSG_ACTIVATE: 'Activar',
        MSG_INACTIVATE: 'Inactivar',
        MSG_EDIT: 'Editar',
        MSG_ALERT_ACTIVATE_SUBMODULE : '¿Esta seguro de Activar el modulo ## ?',
        MSG_ALERT_INACTIVATE_SUBMODULE : '¿Esta seguro de Inactivar el modulo ## ?',
        UPDATE_SUBMODULE_TITLE: 'Actualización Estado',
        UPDATE_SUBMODULE_MSG: 'Estado Actualizado Exitosamente para el submodulo ',
        BUTTON_NEW: 'Nuevo',
        URL_SUBMODULE_NEW: INTERNAL_ROUTES.MODULE_NEW,        
        URL_SUBMODULE_EDIT: INTERNAL_ROUTES.MODULE_EDIT,
        URL_SUBMODULE_LIST: INTERNAL_ROUTES.SUBMODULE_LIST,
    },
}