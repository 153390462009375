import {
    ChangeDetectionStrategy,
    Component
} from '@angular/core';
  import { FormControl, Validators } from '@angular/forms';
import { MODAL_CONSTANTS } from '@data/constants';
  import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
  
@Component({
selector: 'app-input-dialog',
templateUrl: './input-dialog.component.html',
styleUrls: ['./input-dialog.component.scss'],
changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputDialogComponent {
  title: string;
  set initialValue(value: string) {
    this.input.setValue(value);
    this.document = value;    
  }
  message: string;
  input = new FormControl('', Validators.required);
  question: string;

  constructor(public activeModal: NgbActiveModal) { }

  public dataMsg = MODAL_CONSTANTS.CUSTOM;
  public document: string;
}
  