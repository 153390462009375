import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "environments/environment";
import { of } from "rxjs";

/**
 * Clase para el manejo generico de errores
 */
export class ApiClass {
    
    public url = environment.uri;
    public isProduction = environment.production;
    
    constructor (
        protected http: HttpClient,
    ) {

    }

    error(errorHttp: HttpErrorResponse) {
        let errorMessage = '';
        if(errorHttp.error instanceof ErrorEvent) {
            errorMessage = errorHttp.error.message;            
        } else {
            errorMessage = `Error Code: ${errorHttp.status}  - Message: ${errorHttp.message}`;
        }
        console.log('errorMessage' , errorMessage);
        return of({error: true, msg: errorMessage, data: null});
    }
}