import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GENERAL_CONSTANTS } from '@data/constants';
import { API_ROUTES, INTERNAL_ROUTES } from '@data/constants/routes';
import { IApiCompany, IApiCompanyModule, IApiUser } from '@data/interfaces';
import { IApiModule, IApiModuleResponse } from '@data/interfaces/services/iapi-module.metadata';
import { ApiClass } from '@data/schema/ApiClass.class';
import { Observable } from 'rxjs';
import { catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModuleService extends ApiClass {
  

  constructor(http: HttpClient,
    private router: Router) {
    super(http);
  }


  /**
   * Get all modules from api
   */
   getAllModules(): Observable<any> {
    const response = { error: false, msg: '', data: null };
    return this.http.get<IApiModuleResponse>(API_ROUTES.MODULE.LIST)
      .pipe(
        map(r => {
          response.data = r.authModules;
          return response;
        }),
        catchError(this.error)
      );
  }

  /**
   * Get module by Id from api
   */
  getModuleById(moduleId: string): Observable<any> {
      return this.http.get<IApiModuleResponse>(API_ROUTES.MODULE.FIND_MODULE_BY_ID+'/'+moduleId)
     .pipe(
       map( r => {       
         return r;
       }),
       catchError(this.error)
     );
   }

  /**
   * Get all parent modules from api
   */
  getAllParentModules(): Observable<any> {
    return this.http.get<IApiModuleResponse>(API_ROUTES.MODULE.PARENT)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
   * Get all parent modules from api
   */
  getAllChildModulesByParentId(moduleParentId: number): Observable<any> {
    return this.http.get<IApiModuleResponse>(API_ROUTES.MODULE.SUBMODULE_LIST+'/'+moduleParentId)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
   * Get all parent modules from api
   */
  getAllChildModulesByRole(roleId:number): Observable<any> {
    return this.http.get<IApiModuleResponse>(API_ROUTES.MODULE.FIND_SUBMODULE_BY_ROLE_ID+`/${roleId}`)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }
  
  /**
   * Create module 
   */
  createModule(authModules: IApiModule
  ): Observable <any> {
    //const response = { error: true, msg: ERRORS_VALIDATIONS_CONSTS.LOGIN.ERROR.USER_NO_FOUND, data: null };    
    //Invocar al servicio de autenticacion
    return this.http.post<IApiModule>(API_ROUTES.MODULE.NEW, authModules)
      .pipe (
        map(r => {          
          console.log('response + msg: ' , r);

          // Validar si no retorno un error al momento de autenticar, para redireccionar al panel principal
          if(r){
            this.router.navigateByUrl(INTERNAL_ROUTES.MODULE_LIST);
          }
          return r;
        }),
        catchError(this.error)      
      );
  }

  /**
   * Update module 
   */
  updateModule(authModules: IApiModule): Observable <any> {
    //const response = { error: true, msg: ERRORS_VALIDATIONS_CONSTS.LOGIN.ERROR.USER_NO_FOUND, data: null };    
    //Invocar al servicio de Actualizar 
    return this.http.put<IApiModule>(API_ROUTES.MODULE.EDIT, authModules)
      .pipe (
        map(r => {
          return r;
        }),
        catchError(this.error)      
      );
  }

  updateStatusModule(authModules: IApiModule) : Observable <any> {  
    authModules.modStatus = (authModules.modStatus == GENERAL_CONSTANTS.STATUS.ACTIVE ? GENERAL_CONSTANTS.STATUS.INACTIVE : GENERAL_CONSTANTS.STATUS.ACTIVE);//Asignar el cambio de estado a la inversa
    //Invocar al servicio de actualizacion del estado
    return this.http.put<IApiModuleResponse>(API_ROUTES.MODULE.UPDATE_STATUS_MODULE, authModules)
      .pipe (
        map(r => {          
          return r;
        }),
      catchError(this.error)      
    );
  }

  /**
   * Metodo encargado de asignar los submodulos a un rol de una compania especifica
   * @param companyId id de la compania
   * @returns 
   */
  addSubmodulesToCompany(companyId: number): Observable<any> {
    //Invocar al servicio de creacion de usuario
    return this.http.post<IApiModuleResponse>(API_ROUTES.MODULE.ADD_SUBMODULES_TO_COMPANY + '/'  + companyId, null)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  getModulesAndSudModulesByUserAndCompany (user: IApiUser, company: IApiCompany): Observable<any> {
    //Invocar al servicio de creacion de usuario
    return this.http.get<IApiModuleResponse>(API_ROUTES.MODULE.FIND_MOD_AND_SUBMOD_BY_USER_AND_COMPANY+`/${user.userId}/companyId/${company.companyId}`)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
   * Metodo encargado de asignar los submodulos a un rol de una compania especifica
   * @param companyId id de la compania
   * @returns 
   */
  addSubmodulesToRole(companyId: number, roleId: number,submoduleId: number): Observable<any> {
    //Invocar al servicio de creacion de usuario
    return this.http.post<IApiModuleResponse>(API_ROUTES.MODULE.ADD_SUBMODULES_TO_ROLE+`/${companyId}/roleId/${roleId}/moduleId/${submoduleId}`, null)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
   * Get all parent modules by company from api
   */
  getAllParentModulesbyCompany(companyId: number): Observable<any> {
    return this.http.get<IApiModuleResponse>(API_ROUTES.COMPANY.MODULES_BY_COMPANY+'/'+companyId)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }
  
  /**
   * Metodo encargado de asignar los submodulos a un rol de una compania especifica
   * @param companyId id de la compania
   * @returns 
   */
  deleteSubmodulesToRole(companyId: number, roleId: number,submoduleId: number): Observable<any> {
    //Invocar al servicio de creacion de usuario
    return this.http.post<IApiModuleResponse>(API_ROUTES.MODULE.DELETE_SUBMODULES_TO_ROLE+`/${companyId}/roleId/${roleId}/moduleId/${submoduleId}`, null)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

}
