import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '@data/services/api/auth.service';
import { SidebarService } from '../../shared/services/sidebar.service'

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.css']
})
export class SkeletonComponent implements OnInit {

  constructor(
    public sidebarservice: SidebarService,
    private router: Router,
    private authService: AuthService
  ) { }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  getSideBarState() {
      return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
      this.sidebarservice.setSidebarState(true);
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
    //Asignar el color del left menu
    this.getLeftMenuColor();

    //Asignar el color del header menu
    this.getHeaderColor();
  }

  /**
   * metodo encargado de asignar el color del menu izquierdo, segun el tipo de empresa
   */
  getLeftMenuColor() {
    if(this.authService.getUser.authUserComp != null && this.authService.getUser.authUserComp.length > 0){
      switch (this.authService.getUser.authUserComp[0].authCompany.leftColor) {
        case '1':
          $('html').attr('class', 'color-sidebar sidebarcolor1');          
          break;
        case '2':
          $('html').attr('class', 'color-sidebar sidebarcolor2');          
          break;
        case '3':
          $('html').attr('class', 'color-sidebar sidebarcolor3');          
          break;
        case '4':
          $('html').attr('class', 'color-sidebar sidebarcolor4');          
          break;
        case '5':
          $('html').attr('class', 'color-sidebar sidebarcolor5');          
          break;
        case '6':
          $('html').attr('class', 'color-sidebar sidebarcolor6');          
          break;
        case '7':
          $('html').attr('class', 'color-sidebar sidebarcolor7');          
          break;
        case '8':
          $('html').attr('class', 'color-sidebar sidebarcolor8');          
          break;
        default:                   
          break;
      }
    }
  }


  /**
   * metodo encargado de asignar el color del menu izquierdo, segun el tipo de empresa
   */
  getHeaderColor() {
    if(this.authService.getUser.authUserComp != null && this.authService.getUser.authUserComp.length > 0){
      $(".switcher-wrapper").toggleClass("switcher-toggled");
      switch (this.authService.getUser.authUserComp[0].authCompany.headerColor) {
        case '#0727d7'://1
          $("html").addClass("color-header headercolor1"), 
          $("body").removeClass("headercolor2 headercolor3 headercolor4 headercolor5 headercolor6 headercolor7 headercolor8");         
          break;
        case '#23282c'://2
          $("html").addClass("color-header headercolor2"), 
          $("html").removeClass("headercolor1 headercolor3 headercolor4 headercolor5 headercolor6 headercolor7 headercolor8");          
          break;
        case '#e10a1f'://3
          $("html").addClass("color-header headercolor3"), 
          $("html").removeClass("headercolor1 headercolor2 headercolor4 headercolor5 headercolor6 headercolor7 headercolor8");
          break;
        case '#157d4c'://4
          $("html").addClass("color-header headercolor4"), 
          $("html").removeClass("headercolor1 headercolor2 headercolor3 headercolor5 headercolor6 headercolor7 headercolor8");
          break;
        case '#673ab7'://5
          $("html").addClass("color-header headercolor5"), 
          $("html").removeClass("headercolor1 headercolor2 headercolor4 headercolor3 headercolor6 headercolor7 headercolor8");
          break;
        case '#795548'://6
          $("html").addClass("color-header headercolor6"), 
          $("html").removeClass("headercolor1 headercolor2 headercolor4 headercolor5 headercolor3 headercolor7 headercolor8");
          break;
        case '#d3094e'://7
          $("html").addClass("color-header headercolor7"), 
          $("html").removeClass("headercolor1 headercolor2 headercolor4 headercolor5 headercolor6 headercolor3 headercolor8");
          break;
        case '#ff9800'://8
          $("html").addClass("color-header headercolor8"), 
          $("html").removeClass("headercolor1 headercolor2 headercolor4 headercolor5 headercolor6 headercolor7 headercolor3");
          break;
        default:                    
          break;
      } 
    }    
  }

}
