<a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    <ng-container *ngIf="userCurrent.useravatar; else avatarDefaultImg">
        <img src="userCurrent.useravatar" class="user-img" alt="user avatar">
    </ng-container>
    <ng-template #avatarDefaultImg>        
        <img src="https://freeiconshop.com/wp-content/uploads/edd/person-outline-filled.png" class="user-img" alt="user avatar">
    </ng-template>
    <div class="user-info ps-3">
        <p class="user-name mb-0">{{userCurrent.firstName}}</p>
        <p class="designattion mb-0">{{userCurrent.lastName}}</p>
    </div>
</a>
<ul class="dropdown-menu dropdown-menu-end">
    <li>
        <a class="dropdown-item" href="#" [routerLink]="URL_UPDATE_PASSWORD">
            <i class="bx bx-edit"></i>
            <span>{{data.UPDATE_PASSWORD}}</span>
        </a>
    </li>
    <li>
        <a class="dropdown-item" href="#" [routerLink]="URL_USER_PROFILE">
            <i class="bx bx-user-pin"></i>
            <span>{{data.GET_PROFILE}}</span>
        </a>
    </li>
    <!--<li>
        <a class="dropdown-item" href="javascript:;"><i class="bx bx-user"></i><span>Perfil</span></a>
    </li>
    <li>
        <a class="dropdown-item" href="javascript:;"><i class="bx bx-cog"></i><span>Configuracion</span></a>
    </li>
    <li>
        <a class="dropdown-item" href="javascript:;"><i class='bx bx-home-circle'></i><span>Tablero de Control</span></a>
    </li>-->         
    <li>
        <div class="dropdown-divider mb-0"></div>
    </li>
    <li><a class="dropdown-item" (click)="logout()"><i class='bx bx-log-out-circle'></i><span>{{data.LOGOUT}}</span></a>
    </li>
</ul>
