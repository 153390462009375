import { IMAGES_ROUTES } from "@data/constants/routes";

export const SUPERADMIN_CONSTANTS = {
    TITLES: {
        SUPERADMIN: 'Super Administrador',
        USERS: 'Usuarios',
        MODULES: 'Modulos',
        CREATE_ADMIN: 'Crear Administrador',
    },
    ADMINS: {
        TITLE: 'CREAR ADMINISTRADOR',
        RADIO_BUTTON_FIND_USER: 'Buscar Usuario',
        RADIO_BUTTON_USER_NEW: 'Crear Usuario Nuevo',
        INPUT_FIRST_NAME:'Nombres(*):',
        INPUT_FIRST_NAME_PLACEHOLDER:'Ingrese Nombres',
        INPUT_LAST_NAME:'Apellidos(*):',
        INPUT_LAST_NAME_PLACEHOLDER:'Ingrese Apellidos',
        INPUT_USERNAME:'Usuario(*):',
        INPUT_USERNAME_PLACEHOLDER:'Ingrese Usuario',
        INPUT_PASSWORD: 'Contraseña(*):',
        INPUT_PASSWORD_PLACEHOLDER: 'Ingrese Contraseña',        
        INPUT_EMAIL:'Correo Electrónico(*):',
        INPUT_EMAIL_PLACEHOLDER:'Ingrese Correo Electrónico',
        INPUT_DOCUMENT:'Número de Documento(*):',
        INPUT_DOCUMENT_PLACEHOLDER:'Ingrese Número de Documento',
        INPUT_DOCUMENT_TYPE:'Tipo de Documento(*):',
        INPUT_COMPANY:'Empresas(*):',
        INPUT_COMPANY_PLACEHOLDER:'Seleccione una Empresa',
        BUTTON_CREATE: 'Crear Administrador',
        MSG_REGISTER_SUPERADMIN_TITLE: 'Registrar Administrador',
        MSG_REGISTER_SUPERADMIN_DESC: 'Se Registro Exitosamente el Administrador ',
        MSG_ADD_COMPANY_SUPERADMIN_TITLE: 'Asignación de Empresa',
        MSG_ADD_COMPANY_SUPERADMIN_DESC_1: 'Se Asigno Exitosamente el Usuario ',
        MSG_ADD_COMPANY_SUPERADMIN_DESC_2: 'a la Empresa ',
        MSG_ADD_ROLE_SUPERADMIN_TITLE: 'Asignación Rol Administrador',
        MSG_ADD_ROLE_SUPERADMIN_DESC_1: 'Se Registro Exitosamente el Rol ',
        MSG_ADD_ROLE_SUPERADMIN_DESC_2: 'al Usuario ',
        MSG_VALIDATION_USER_EXIST_TITLE:'Usuario Existe',
        MSG_VALIDATION_USER_EXIST_DESC_1: 'Actualmente el usuario existe en el sistema',
        MSG_VALIDATION_USER_EXIST_DESC_2: '¿desea asignar el rol Administrador al usuario para la empresa ',
        MSG_VALIDATION_USER_EXIST_DESC_3: '¿desea crear el usuario para la Empresa ',
        MSG_VALIDATION_IS_SUPERADMIN: 'El usuario ingresado ya es Administrador de esta empresa'        
    },
    MODULE: {
        TITLE: 'CREAR ADMINISTRADOR',
        INPUT_COMPANY:'Empresas(*):',
        INPUT_COMPANY_PLACEHOLDER:'Seleccione una Empresa',
        INPUT_MODULES_SELECTED:'Modulos Seleccionados:',
        INPUT_MODULES_AVAILABLES:'Modulos disponibles:',
        LOGO: IMAGES_ROUTES.FORGOT_PASSWORD_LOGO,
        DESCRIPTION_UNO: 'Ingrese su Correo Electrónico registrado en el sistema',
        DESCRIPTION_DOS: 'para restablecer la contraseña',
        INPUT_EMAIL:'Correo Electrónico(*):',
        INPUT_EMAIL_PLACEHOLDER:'Ingrese Correo Electrónico',
        BUTTON_RETRIEVE: 'Recuperar Contraseña',
        BUTTON_BACK_TO_LOGIN: 'Volver al Login',
        MSG_ASSIGN_MODULE_TITLE: 'Asignar Modulo',
        MSG_ASSIGN_MODULE_DESC: 'Se Asigno Exitosamente el modulo ',
        MSG_ASSIGN_MODULE_DESC_2: 'a la Empresa ',
        MSG_ASSIGN_SUBMODULE_TITLE: 'Asignar Submodulo',
        MSG_ASSIGN_SUBMODULE_DESC: 'Se asignaron los submodulos correctamente',
    }
}    