import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { INTERNAL_ROUTES } from '@data/constants/routes';
import { AuthService } from '@data/services/api/auth.service';

/**
 * Clase para el manejo del GUARD de autenticacion, si el usuario esta logueado y tiene un loclaStorage,
 * Retorna true y se peude ver el componente donde este el guard
 * en caso contrario, returna false y redirecciona a la pagina de login
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }
  
  canActivate(
    next: ActivatedRouteSnapshot, //Permite acceder a los datos
    state: RouterStateSnapshot
  ): boolean {
    const currentUser = this.authService.getUser;
    //console.log('AUTH GUARD currentUser: ' , currentUser);
    if(currentUser)
    {
      // valida si el usuario ya seleccion una empresa, en caso de que sea false redirecciona al login
      if(currentUser.authenticated != true)
      {        
        this.authService.logout();
        this.router.navigateByUrl(INTERNAL_ROUTES.AUTH_LOGIN);
        return false;
      }
      return true;
    }

    this.router.navigate([INTERNAL_ROUTES.AUTH_LOGIN],{
      queryParams: {returnUrl: state.url}
    });
    return false;    
  }  
}
