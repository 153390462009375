import { INTERNAL_ROUTES } from "@data/constants/routes";

export const EQUIPOS_CONSTANTS = {
  TITLES: {
      EQUIPOS: 'Equipos',
      EQUIPOS_NEW: 'Registro de Equipos',
      LIST_ALL_EQUIPOS: 'Listado de Equipos',
      ENTER_EQUIPOS: 'Ingresar Equipo',
      EQUIPOS_EDIT: 'Actualizar Equipo',
      MANTENIMIENTO_EQUIPO_LIST: 'Mantenimiento de Equipos',

      LIST_ALL_MANTENIMIENTO_EQUIPOS: 'Listado de Mantenimientos',
      ENTER_MANTENIMIENTO_EQUIPOS: 'Ingresar Mantenimiento',
      MANTENIMIENTO_EQUIPOS_EDIT: 'Actualizar Mantenimiento',

      // PRODUCTION_LIST_ALL_ORDER: 'Ver Ordenes Producción',
      // PRODUCTION_NEW_ORDER: 'Orden Producción',
      // //PRODUCTION_PRODUCT_SHEETS: 'Fichas de Productos',
      // PRODUCT_SHEET_VIEW: 'Ver Ficha Producto',
      // PRODUCCION_ORDER_EDIT: 'Editar Orden Produccion',
  },
  LIST: {
      BUTTON_NEW: 'Ingresar Equipo',
      URL_EQUIPO_NEW: INTERNAL_ROUTES.KATIA_EQUIPO_NEW,
      SEARCH_EQUIPOS: 'Buscar Equipos',
      MSG_CONFIRM_INACTIVATE_EQUIPO : '¿Esta seguro de Inactivar el Equipo ## ?',
      MSG_CONFIRM_ACTIVATE_EQUIPO: '¿Esta seguro de Activar el Equipo ## ?',
      UPDATE_EQUIPO_TITLE: 'Actualización Equipos',
      UPDATE_EQUIPO_MSG: 'Equipo Actualizado Exitosamente',

      BUTTON_MANTENIMIENTO_NEW: 'Registrar Mantenimiento',
      URL_MANTENIMIENTO_EQUIPO_NEW: INTERNAL_ROUTES.KATIA_MANTENIMIENTO_EQUIPO_NEW,
      SEARCH_MANTENIMIENTO: 'Buscar Equipos',
      MSG_CONFIRM_INACTIVATE_MANTENIMIENTO_EQUIPO: '¿Esta seguro de Inactivar el Mantenimiento del Equipo ## ?',
      MSG_CONFIRM_ACTIVATE_MANTENIMIENTO_EQUIPO: '¿Esta seguro de activar el Mantenimiento del Equipo ## ?',
      UPDATE_MANTENIMIENTO_EQUIPO_TITLE: 'Actualización Mantenimiento de Equipos',
      UPDATE_MANTENIMIENTO_EQUIPO_MSG: 'Mantenimiento Actualizado Exitosamente',

      SEARCH_EQUIPO_STATUS:'Buscar equipos por estado',
      SEARCH_TIPO_MANTENIMIENTO: 'Buscar por tipo de mantenimiento',
  },
  NEW_EDIT: {
    CODIGO: 'Codigo',
    NOMBRE: 'Nombre',
    UBICACION: 'Ubicacion',
    TIPO_EQUIPO: 'Tipo de Equipo',
    FECHA_COMPRA: 'Fecha de Compra',
    PROVEEDOR: 'Proveedor',
    FOTO: 'Foto',
    CLASE_MOTOR: 'Clase de Motor',
    MARCA: 'Marca',
    MODELO: 'Modelo',
    SERIE: 'Serie',
    POTENCIA: 'Potencia',
    VOLTAJE: 'Voltaje',
    LUBRICANTE: 'Lubricante',
    ACCESORIO: 'Accesorio',
    URL_EQUIPOS_LIST: INTERNAL_ROUTES.KATIA_EQUIPOS_LIST,
    BUTTON_BACK: 'Regresar',
    BUTTON_GUARDAR: 'Guardar',
    MSG_REGISTER_EQUIPO_TITLE: 'Registrar Equipo',
    MSG_REGISTER_EQUIPO_DESC: 'Se Registro Exitosamente el Equipo ',
    MSG_EDIT_EQUIPO_TITLE: 'Actualizar Equipo',
    MSG_EDIT_EQUIPO_DESC: 'Se Actualizo Exitosamente el Equipo ##1',
    MSG_GET_EQUIPO_TITLE: 'Información Equipo',
    MSG_GET_EQUIPO_DESC: 'No se encontro información para el Equipo seleccionado',

    EQUIPO: 'Equipo',
    TIPO_MANTENIMIENTO: 'Tipo de mantenimiento',
    OBSERVACION: 'Observacion',
    RANGO_MANTENIMIENTO: 'Rango para efectuar mantenimiento (Días)',
    URL_MANTENIMIENTO_EQUIPOS_LIST: INTERNAL_ROUTES.KATIA_MANTENIMIENTO_EQUIPO_LIST,
    MSG_REGISTER_MANTENIMIENTO_EQUIPO_TITLE: 'Registrar Mantenimiento Equipo',
    MSG_REGISTER_MANTENIMIENTO_EQUIPO_DESC: 'Se Registro Exitosamente el Mantenimiento del Equipo ',
    MSG_EDIT_MANTENIMIENTO_EQUIPO_TITLE: 'Actualizar Mantenimiento Equipo',
    MSG_EDIT_MANTENIMIENTO_EQUIPO_DESC: 'Se Actualizo Exitosamente el Mantenimiento del Equipo ##1',
    MSG_GET_MANTENIMIENTO_EQUIPO_TITLE: 'Información Mantenimiento Equipo',
    MSG_GET_MANTENIMIENTO_EQUIPO_DESC: 'No se encontro información para el Mantenimiento seleccionado',
  },
  TABLES:{
    TH_CODIGO_EQUIPO: 'Código',
    TH_NOMBRE_EQUIPO: 'Nombre',
    TH_UBICACION_EQUIPO: 'Ubicacion',
    TH_STATUS: 'Estado',

    TH_EQUIPO: 'Equipo',
    TH_TIPO_MAN: 'Tipo de Mantenimiento',
    TH_FECHA_MAN: 'Fecha Mantenimiento',
    TH_RANGO_MAN: 'Rango Mantenimiento',

    TH_EQUIPOS_PRODUCTO: 'EQUIPOS',
    TH_EQUIPOS_OBSERVACION: 'OBSERVACION'

  },
  STATUS: {
    ACTIVE: 1,
    MSG_ACTIVATE: 'Activar',
    INACTIVE: 0,
    MSG_INACTIVATE: 'Inactivar',
    MSG_ACTIVE: 'Activo',
    MSG_INACTIVE: 'Inactivo',
  },

}
