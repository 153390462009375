import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { IMAGES_ROUTES, INTERNAL_ROUTES } from '@data/constants/routes';
import { AuthService } from '@data/services/api/auth.service';
import { SidebarService } from "@shared/services/sidebar.service";

import * as $ from 'jquery';

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.css']
})
export class LeftNavComponent implements OnInit {
    public URL_USER_HOME: string = INTERNAL_ROUTES.PANEL_USER_HOME;
    public MAIN_LOGO_ICON: string = IMAGES_ROUTES.MAIN_LOGO_ICON;  

    constructor(
        public sidebarservice: SidebarService,
        private router: Router,
        public authService: AuthService
    ) { 
        router.events.subscribe( (event: Event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
            }
            //Validacion de with, para evitar que tome un valor indefinido
            if (event instanceof NavigationEnd && $(window).width() < 1025 && ( document.readyState == 'complete' || false ) ) {
                this.toggleSidebar();
                // Hide loading indicator        
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator // Present error to user
                console.log(event.error);
            }
        });
    }  

  toggleSidebar() {
    //this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
    this.sidebarservice.setSidebarState(this.sidebarservice.getSidebarState());
    
    if ($(".wrapper").hasClass("nav-collapsed")) {
        // unpin sidebar when hovered
        $(".wrapper").removeClass("nav-collapsed");
        $(".sidebar-wrapper").unbind( "hover");
    } else {
        $(".wrapper").addClass("nav-collapsed");
        $(".sidebar-wrapper").hover(
            function () {
                $(".wrapper").addClass("sidebar-hovered");
            },
            function () {
                $(".wrapper").removeClass("sidebar-hovered");
            }
        )
    }
  }

  getSideBarState() {
      return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
      this.sidebarservice.setSidebarState(true);
  }


  ngOnInit() {      

  }

}
