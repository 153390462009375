import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { INTERNAL_PATHS, INTERNAL_ROUTES } from '@data/constants/routes';
import { SkeletonComponent } from '@layout/skeleton/skeleton.component';
import { Page401Component } from '@modules/server/page401/page401.component';
import { Page404Component } from '@modules/server/page404/page404.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: INTERNAL_ROUTES.AUTH_LOGIN,
    pathMatch: 'full'
  },
  {
    path: INTERNAL_PATHS.SERVER_ERROR_404,
    component: Page404Component
  },
  {
    path: INTERNAL_PATHS.SERVER_ERROR_401,
    component: Page401Component
  },
  {
    path: INTERNAL_PATHS.AUTH_DEFAULT,
    loadChildren: () =>
    import('@modules/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: INTERNAL_PATHS.PANEL_DEFAULT,
    component: SkeletonComponent,
    children: [
      {
        path: INTERNAL_PATHS.PANEL_USER_HOME,
        loadChildren: () =>
        import('@modules/user/user.module').then((m) => m.UserModule)
      }
    ]
  },
  {
    path: INTERNAL_PATHS.USER_GENERAL_DEFAULT,
    component: SkeletonComponent,
    children: [
      {
        path: INTERNAL_PATHS.USER_GENERAL_HOME,
        loadChildren: () =>
        import('@modules/user-general/user-general.module').then((m) => m.UserGeneralModule)
      }
    ]
  },
  {
    path: INTERNAL_PATHS.COMPANY_DEFAULT,
    component: SkeletonComponent,
    children: [
      {
        path: INTERNAL_PATHS.COMPANY_HOME,
        loadChildren: () =>
        import('@modules/company/company.module').then((m) => m.CompanyModule)
      }
    ]
  },
  {
    path: INTERNAL_PATHS.MODULE_DEFAULT,
    component: SkeletonComponent,
    children: [
      {
        path: INTERNAL_PATHS.MODULE_HOME,
        loadChildren: () =>
        import('@modules/module/module.module').then((m) => m.ModuleModule)
      }
    ]
  },
  {
    path: INTERNAL_PATHS.ROLE_DEFAULT,
    component: SkeletonComponent,
    children: [
      {
        path: INTERNAL_PATHS.ROLE_HOME,
        loadChildren: () =>
        import('@modules/role/role.module').then((m) => m.RoleModule)
      }
    ]
  },
  {
    path: INTERNAL_PATHS.SUPERADMIN_DEFAULT,
    component: SkeletonComponent,
    children: [
      {
        path: INTERNAL_PATHS.SUPERADMIN_HOME,
        loadChildren: () =>
        import('@modules/superadmin/superadmin.module').then((m) => m.SuperadminModule)
      }
    ]
  },
  {
    path: INTERNAL_PATHS.MATERIAL_DEFAULT,
    component: SkeletonComponent,
    children: [
      {
        path: INTERNAL_PATHS.MATERIAL_HOME,
        loadChildren: () =>
        import('@modulesKatia/material/material.module').then((m) => m.MaterialModule)
      }
    ]
  },
  {
    path: INTERNAL_PATHS.PROVIDER_DEFAULT,
    component: SkeletonComponent,
    children: [
      {
        path: INTERNAL_PATHS.PROVIDER_HOME,
        loadChildren: () =>
        import('@modulesKatia/proveedor/proveedor.module').then((m) => m.ProveedorModule)
      }
    ]
  },
  {
    path: INTERNAL_PATHS.ORDEN_COMPRA_DEFAULT,
    component: SkeletonComponent,
    children: [
      {
        path: INTERNAL_PATHS.ORDEN_COMPRA_HOME,
        loadChildren: () =>
        import('@modulesKatia/orden-compra/orden-compra.module').then((m) => m.OrdenCompraModule)
      }
    ]
  },
  {
    path: INTERNAL_PATHS.QUALITY_DEFAULT,
    component: SkeletonComponent,
    children: [
      {
        path: INTERNAL_PATHS.QUALITY_HOME,
        loadChildren: () =>
        import('@modulesKatia/calidad/calidad.module').then((m) => m.CalidadModule)
      }
    ]
  },
  {
    path: INTERNAL_PATHS.PRODUCTION_DEFAULT,
    component: SkeletonComponent,
    children: [
      {
        path: INTERNAL_PATHS.PRODUCTION_HOME,
        loadChildren: () =>
        import('@modulesKatia/produccion/produccion.module').then((m) => m.ProduccionModule)
      }
    ]
  },
  {
    path: INTERNAL_PATHS.BODEGA_DEFAULT,
    component: SkeletonComponent,
    children: [
      {
        path: INTERNAL_PATHS.BODEGA_HOME,
        loadChildren: () =>
        import('@modulesKatia/bodega/bodega.module').then((m) => m.BodegaModule)
      }
    ]
  },
  {
    path: INTERNAL_PATHS.COMERCIAL_DEFAULT,
    component: SkeletonComponent,
    children: [
      {
        path: INTERNAL_PATHS.COMERCIAL_HOME,
        loadChildren: () =>
        import('@modulesKatia/comercial/comercial.module').then((m) => m.ComercialModule)
      }
    ]
  },
  {
    path: INTERNAL_PATHS.EQUIPO_DEFAULT,
    component: SkeletonComponent,
    children: [
      {
        path: INTERNAL_PATHS.EQUIPO_HOME,
        loadChildren: () =>
        import('@modulesKatia/equipos/equipos.module').then((m) => m.EquiposModule)
      }
    ]
  },
  {
    path: INTERNAL_PATHS.PROGRAMACION_DEFAULT,
    component: SkeletonComponent,
    children: [
      {
        path: INTERNAL_PATHS.PROGRAMACION_HOME,
        loadChildren: () =>
        import('@modulesKatia/programacion/programacion.module').then((m) => m.ProgramacionModule)
      }
    ]
  },

  {//Redireccionar la pagina por default (auth/login), en el caso de que no exista el routing
    path: '**',
    redirectTo: INTERNAL_ROUTES.AUTH_LOGIN,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
