<a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    <i class='bx bx-bell'></i>
</a>
<div class="dropdown-menu dropdown-menu-end">

    <div class="header-notifications-list" [perfectScrollbar]>
        <a class="dropdown-item" href="#" (click)="$event.preventDefault()" [routerLink]="URL_COMPANY_LIST">
            <div class="d-flex align-items-center">
                <div class="notify bg-light-primary text-primary"><i class="bx bx-group"></i>
                </div>
                <div class="flex-grow-1">
                    <h6 class="msg-name">New Customers<span class="msg-time float-end">14 Sec ago</span></h6>
                    <p class="msg-info">5 new user registered</p>
                </div>
            </div>
        </a>
    </div>
</div>