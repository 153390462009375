import { Component, Input, OnInit } from '@angular/core';
import { INTERNAL_ROUTES } from '@data/constants/routes';
import { BreadcrumbInfo } from './ibreadcrumb.metadata';

@Component({
  selector: 'app-breadcrumb-general',
  templateUrl: './breadcrumb-general.component.html',
  styleUrls: ['./breadcrumb-general.component.scss']
})
export class BreadcrumbGeneralComponent implements OnInit {

  public URL_USER_HOME: string = INTERNAL_ROUTES.PANEL_USER_HOME;  
  @Input() dataBreadcrumb: BreadcrumbInfo;
  
  constructor() { }

  ngOnInit(): void {
  }

}
