import { Component, OnInit } from '@angular/core';
import { FOOTER_CONSTANTS, GENERAL_CONSTANTS } from '@data/constants';
import { AuthService } from '@data/services/api/auth.service';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})
export class Page404Component implements OnInit {

  public dataFooter = FOOTER_CONSTANTS;
  public dataGeneral = GENERAL_CONSTANTS.SERVER;
  
  constructor(
    public authService: AuthService
  ) { }

  ngOnInit(): void {
  }

}
