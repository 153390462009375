import { Component, OnInit } from '@angular/core';
import { INTERNAL_ROUTES } from '@data/constants/routes';

@Component({
  selector: 'app-header-tasks, [app-header-tasks]',
  templateUrl: './header-tasks.component.html',
  styleUrls: ['./header-tasks.component.css']
})
export class HeaderTasksComponent implements OnInit {

  public URL_COMPANY_LIST: string = INTERNAL_ROUTES.COMPANY_LIST;
  constructor() { }

  ngOnInit(): void {
  }

}
