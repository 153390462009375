import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class SearchModalComponent implements OnInit {
  public tablaContainer = document.getElementById('tablaContainer');
  titulo:string

  constructor(public dialogRef: MatDialogRef<SearchModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.titulo = data.titulo;

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.tablaContainer = document.getElementById('tablaContainer');
    if (this.tablaContainer) {
      this.construirTabla(this.data.datos);
    } else {
      console.error('No se encontró el contenedor de tabla');
    }

    // Obtener la referencia del input y la tabla
    const input = document.getElementById("filtro") as HTMLInputElement;
    const table = document.getElementById("search-table") as HTMLTableElement;

    // Agregar un listener al campo de entrada para filtrar la tabla
    input.addEventListener("keyup", () => {
        const filter = input.value.toLowerCase();
        const rows = table.getElementsByTagName("tr");

        // Iterar sobre las filas de la tabla (empezando después del encabezado)
        for (let i = 1; i < rows.length; i++) {
            const cells = rows[i].getElementsByTagName("td");
            let rowContainsFilter = false;

            // Iterar sobre las celdas de la fila
            for (let j = 0; j < cells.length; j++) {
                const cell = cells[j];
                if (cell) {
                    const cellText = cell.textContent || cell.innerText;
                    if (cellText.toLowerCase().indexOf(filter) > -1) {
                        rowContainsFilter = true;
                        break;
                    }
                }
            }

            // Mostrar u ocultar la fila según si contiene el filtro
            if (rowContainsFilter) {
                rows[i].style.display = "";
            } else {
                rows[i].style.display = "none";
            }
        }
    });


  }

  construirTabla(data): void {
    const tabla = document.createElement('table');
    tabla.id = 'search-table';
    tabla.classList.add('table', 'mb-0', 'table-hover');
    const cabecera = tabla.createTHead();
    const cabeceraRow = cabecera.insertRow();

    // Agregar las cabeceras basadas en las claves de la primera fila de datos
    const primerProducto = data[0];
    Object.keys(primerProducto).forEach(key => {
      const th = document.createElement('th');
      th.classList.add('table-light','text-center','mat-header-cell')
      const text = document.createTextNode(key.toUpperCase());
      th.appendChild(text);
      cabeceraRow.appendChild(th);
    });

    const cuerpo = document.createElement('tbody');

    data.forEach(item => {
      const fila = cuerpo.insertRow();

      fila.addEventListener('click', () => this.seleccionarItem(item));

      // Agregar celdas para cada propiedad del producto
      Object.values(item).forEach(valor => {
        let cell = fila.insertCell();
        if (Array.isArray(valor)) {

          let ul = document.createElement('ul');
          (valor).forEach(item => {
            const li = document.createElement('li');
            let liHTML = "";
            Object.entries(item).forEach(([key, value]) => {
              liHTML += `<b>${key}</b>: ${value} `;
            });
            li.innerHTML = liHTML.trim();
            ul.appendChild(li);
          });

          cell.appendChild(ul);
        } else {
          cell.textContent = String(valor);
        }
      });
    });

    tabla.appendChild(cuerpo);
    this.tablaContainer.appendChild(tabla);
  }

  seleccionarItem(data){
    this.dialogRef.close(data);
  }
  cerrarDialog(){
    this.dialogRef.close();
  }
}
