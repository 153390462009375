import { Component, Output, EventEmitter, Input, OnChanges, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ERRORS_VALIDATIONS_CONSTS } from '@data/constants';
import { AUTOCOMPLETE_CONSTANTS } from '@data/constants/pages/general/autocomplete-const';
import { IApiCompany, IApiMaterial, IApiModule, IApiUser } from '@data/interfaces';
import { IApiDepartamento, IApiMunicipio } from '@data/interfaces/services/iapi-geographic.metadata';
import { IApiProveedor } from '@data/interfaces/services/iapi-provider.metadata';
import { AuthService } from '@data/services/api/auth.service';
import { CompanyService } from '@data/services/api/company.service';
import { GeographicService } from '@data/services/api/geographic.service';
import { MaterialService } from '@data/services/api/material.service';
import { ModuleService } from '@data/services/api/module.service';
import { ProveedorService } from '@data/services/api/proveedor.service';
import { UserService } from '@data/services/api/user.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AutoCompleteEnum } from './autocomplete.enum';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnChanges, OnInit {

  public dataValidations = ERRORS_VALIDATIONS_CONSTS.SUPERADMIN.VALIDATIONS;
  public data = AUTOCOMPLETE_CONSTANTS;

  //FormControl Generico, sirve para todas las listas
  autoCompleteControl = new FormControl();
  formAutocomplete;

  //Output Generico para retornar cualquier dato
  @Output() autocompleteEvent = new EventEmitter<any>();

  public autocompleteLenght : number;

  //Variables con el objeto filtrado
  filteredOptions: Observable<any[]>;

  //Variables para el autocomplete de Compañias
  companies: IApiCompany[] = [];
  selectedCompany: IApiCompany;

  //Variables para el autocomplete de Usuarios
  users: IApiUser[] = [];
  selectedUser: IApiUser;

  //Variables para el autocomplete de Modulos Padres
  parentModules: IApiModule[] = [];
  selectedParentModule: IApiModule;

  //Variables para el autocomplete de Proveedores
  providers: IApiProveedor[] = [];
  selectedProvider: IApiProveedor;

  //Variables para el autocomplete de Departamentos
  departments: IApiDepartamento[] = [];
  selectedDepartment: IApiDepartamento;

  //Variables para el autocomplete de Modulos Padres
  cities: IApiMunicipio[] = [];
  selectedCity: IApiMunicipio;

  //Variables para el autocomplete de Materiales por Proveedor
  materials: IApiMaterial[] = [];
  selectedMaterial: IApiMaterial;

  //Input con el tipo de autocomplete a generar, en caso de ser Default no muestra nada
  @Input() typeAutoComplete: AutoCompleteEnum = AutoCompleteEnum.DEFAULT;
  //Input con la clase CSS para el autocomplete
  @Input() classCss: number;
  //Input con el usuario seleccionado
  @Input() user: IApiUser;
  //Input con el placeHolder de autocomplete a generar
  @Input() placeHolder: string = '';
  //Input con el tipo de autocomplete a generar, en caso de ser Default no muestra nada
  @Input() departmentId: IApiDepartamento;
  //Input con el tipo de autocomplete a generar, en caso de ser Default no muestra nada
  @Input() cityId: IApiMunicipio;
  //Input con el tipo de autocomplete a generar, en caso de ser Default no muestra nada
  @Input() departmentSelected: IApiDepartamento;
  //Input con el tipo de autocomplete a generar, en caso de ser Default no muestra nada
  @Input() citySelected: IApiMunicipio;
  //Input con el tipo de autocomplete a generar, en caso de ser Default no muestra nada
  @Input() providerSelected: IApiProveedor;
  //Input con el usuario seleccionado
  @Input() companySelected: any;

  public autoCompleteEnum = AutoCompleteEnum;


  constructor(
    private companyService: CompanyService,
    private userService: UserService,
    private moduleService: ModuleService,
    private authService: AuthService,
    private providerService: ProveedorService,
    private geographicService: GeographicService,
    private changeDetectorRef: ChangeDetectorRef,
    private materialService: MaterialService
  ) {
    this.user = {} as IApiUser;

  }

  ngOnInit(): void {
    this.formAutocomplete = new FormGroup({
      identifier: new FormControl('', [Validators.required])
    });

    switch (this.typeAutoComplete) {
      case this.autoCompleteEnum.COMPANY:
        this.getAllCompanies();
        break;
      case this.autoCompleteEnum.USERS:
        this.getAllUsers();
        break;
      case this.autoCompleteEnum.PARENT_MODULES:
        this.getAllParentModules();
        break;
        case this.autoCompleteEnum.PROVIDERS:
        this.getAllActiveProviders();
        break;
      case this.autoCompleteEnum.COMPANIES_BY_USER:
        this.getAllCompaniesByUser();
        break;
      case this.autoCompleteEnum.DEPARTMENTS:
        this.getAllDepartments();
        break;
      case this.autoCompleteEnum.CITIES:
        this.getCitiesByDepartment();
        break;
      case this.autoCompleteEnum.PARENT_MODULES_BY_COMPANY:
        this.getAllParentModulesByCompany();
        break;
      case this.autoCompleteEnum.MATERIALS:
        this.getMaterialsByProvider();
        break;
      default:
        break;
    }

    this.formAutocomplete.get('identifier').valueChanges.subscribe(value => {
      this.initiateFilter(value);
    });
  }

  /**
   * Metodo encargado de validar el tipo de autocomplete a generar cuando se carga
   * la pagina
   */
  ngOnChanges(): void {
    console.log('ngOnChanges',this.typeAutoComplete)
    switch (this.typeAutoComplete) {
      case this.autoCompleteEnum.COMPANY:
        this.getAllCompanies();
        break;
      case this.autoCompleteEnum.USERS:
        this.getAllUsers();
        break;
      case this.autoCompleteEnum.PARENT_MODULES:
        this.getAllParentModules();
        break;
      case this.autoCompleteEnum.COMPANIES_BY_USER:
        this.getAllCompaniesByUser();
        break;
      case this.autoCompleteEnum.PROVIDERS:
        this.getAllActiveProviders();
        break;
      case this.autoCompleteEnum.DEPARTMENTS:
        this.getAllDepartments();
        break;
      case this.autoCompleteEnum.CITIES:
        this.getCitiesByDepartment();
        break;
      case this.autoCompleteEnum.MATERIALS:
        this.getMaterialsByProvider();
        break;
      case this.autoCompleteEnum.PARENT_MODULES_BY_COMPANY:
        this.getAllParentModulesByCompany();
        break;
      default:
        break;
    }

  }


  /**
   * Metodo encargado de consultar el listado de usuarios registrados en el sistema
   */
  getAllUsers() {
    this.userService.getAllUsers().subscribe(r => {
      this.users = r.data;
      this.autocompleteLenght =this.users.length;
      this.initiateFilter();
    });

  }

  /**
   * Metodo encargado de consultar el listado de compañias registradss en el sistema
   */
  getAllCompanies() {
    this.companyService.getAllCompanies().subscribe(r => {
      this.companies = r.authCompanies;
      this.autocompleteLenght =this.companies.length;
      this.initiateFilter();
    });
  }

  /**
   * Metodo encargado de consultar el listado de usuarios registrados en el sistema
   */
  getAllParentModules() {
    this.moduleService.getAllParentModules().subscribe(r => {
      this.parentModules = r.authModules;
      this.autocompleteLenght =this.parentModules.length;
      this.initiateFilter();
    });

  }

  /**
   * Metodo encargado de consultar el listado de compañias asociadas a un usuario registradas en el sistema
   */
  getAllCompaniesByUser() {
    this.companies = this.authService.getUser.authUserComp.map(item => {
      return item.authCompany
      this.initiateFilter();
    });
  }


  /**
   * Metodo encargado de consultar el listado de usuarios registrados en el sistema
   */
  getAllDepartments() {
    this.geographicService.getAllDepartments().subscribe(r => {
      this.departments = r.departamentos;
      if (this.citySelected !== undefined) {
        this.formAutocomplete.get('identifier').setValue(this.citySelected.departamento.nombre);
      }
      this.autocompleteLenght =this.departments.length;
      this.initiateFilter();
    });

  }

  /**
   * Metodo encargado de consultar el listado de compañias asociadas a un usuario registradas en el sistema
   */
  getCitiesByDepartment() {
    if (this.departmentId === undefined) {
      this.cityId = JSON.parse(localStorage.getItem('cityDepartmentId'));
      this.selectedDepartment = JSON.parse(localStorage.getItem('departmentSelected'));
      if (this.cityId !== undefined && this.cityId !== null) {
        if (this.selectedDepartment !== undefined && this.selectedDepartment !== null) {
          if (this.selectedDepartment.id === this.cityId.departamento.id) {
            this.departmentId = this.cityId.departamento;
          } else {
            this.departmentId = this.selectedDepartment;
          }
        } else {
          this.departmentId = this.cityId.departamento;
        }
      } else if (this.selectedDepartment !== undefined) {
        this.departmentId = this.selectedDepartment;
      }
    }

    this.geographicService.getCitiesByDeparmentId(this.departmentId.id).subscribe(r => {
      this.cities = r.municipios;
      if (this.cityId !== undefined && this.cityId !== null) {
        this.formAutocomplete.get('identifier').setValue(this.cityId.nombre);
      }
      localStorage.removeItem('cityDepartmentId');
      localStorage.removeItem('departmentSelected')
      this.autocompleteLenght =this.cities.length;
      this.initiateFilter();
    });
  }

  ordenarArray(array, comparador) {
    return array.sort(comparador);
  }

  /**
   * Metodo encargado de consultar el listado de usuarios registrados en el sistema
   */
  getAllActiveProviders() {
    this.providerService.getAllProviders().subscribe(r => {

      this.ordenarArray(r.proveedores, (a, b) => {
        if (a.proNombre < b.proNombre) {
          return -1;
        }
        if (a.proNombre > b.proNombre) {
          return 1;
        }
        return 0;
      });
      this.providers = r.proveedores;
      if (this.providerSelected !== undefined) {
        this.formAutocomplete.get('identifier').setValue(this.providerSelected.proNombre);
      }
      this.initiateFilter();
    });
  }

  /**
   * Metodo encargado de consultar el listado de materiales por Proveedor registrados en el sistema
   */
  getMaterialsByProvider() {
    this.materialService.findMaterialByProvider(this.providerSelected).subscribe(r => {
      this.ordenarArray(r.materiales, (a, b) => {
        if (a.nomGenerico < b.nomGenerico) {
          return -1;
        }
        if (a.nomGenerico > b.nomGenerico) {
          return 1;
        }
        return 0;
      });
      this.materials = r.materiales;
      this.initiateFilter();
    });
  }

  getAllParentModulesByCompany() {

    this.moduleService.getAllParentModulesbyCompany(this.companySelected.companyId).subscribe(r => {
      this.parentModules = r.authModules;
      this.autocompleteLenght =this.parentModules.length;
      this.initiateFilter();
    });

  }

  /**
   * Metodo encargado de inicializar el filtro y decide cual filtro utilizar
   * dependiendo del tipo de autocomplete a generar
   */
  private initiateFilter(value: string = ''): void {
    console.log("initiateFilter",this.typeAutoComplete)
    switch (this.typeAutoComplete) {
      case this.autoCompleteEnum.COMPANY:
        this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(
          startWith(''),
          map(value => this.filterCompanies(value))
        );
        break;
      case this.autoCompleteEnum.USERS:
        this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(
          startWith(''),
          map(value => this.filterUsers(value))
        );
        break;
      case this.autoCompleteEnum.PARENT_MODULES:
        this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(
          startWith(''),
          map(value => this.filterParentModules(value))
        );
        break;
      case this.autoCompleteEnum.COMPANIES_BY_USER:
        this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(
          startWith(''),
          map(value => this.filterCompaniesByUser(value))
        );
        break;
      case this.autoCompleteEnum.PROVIDERS:
        this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(
          startWith(value),
          map(filterValue  => this.filterProviders(filterValue ))
        );
        break;
      case this.autoCompleteEnum.DEPARTMENTS:
        this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(
          startWith(''),
          map(value => this.filterDepartments(value))
        );
        break;
      case this.autoCompleteEnum.CITIES:
        this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(
          startWith(''),
          map(value => this.filterCities(value))
        );
        break;
      case this.autoCompleteEnum.MATERIALS:
        this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(
          startWith(''),
          map(value => this.filterMaterials(value))
        );
        break;
      case this.autoCompleteEnum.PARENT_MODULES_BY_COMPANY:
        this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(
          startWith(''),
          map(value => this.filterParentModulesByCompany(value))
        );
        break;
      default:
        break;
    }
  }

  /**
   * Metodo encargado de filtrar las compañias por medio del nombre
   */
  private filterCompanies(value: any): IApiCompany[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.companies.filter(company => company.companyName.toLowerCase().includes(filterValue));
    } else {
      return this.companies;
    }

  }

  /**
   * Metodo encargado de filtrar los usuarios por medio del nombre de usuario
   */
  private filterUsers(value: any): IApiUser[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.users.filter(user => user.username.toLowerCase().includes(filterValue));
    } else {
      return this.users;
    }
  }

  /**
   * Metodo encargado de filtrar los modulos por medio del nombre
   */
  private filterParentModules(value: any): IApiModule[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.parentModules.filter(parentModule => parentModule.modName.toLowerCase().includes(filterValue));
    } else {
      return this.parentModules;
    }
  }

  /**
   * Metodo encargado de filtrar las compañias por medio del nombre
   */
  private filterCompaniesByUser(value: any): IApiCompany[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.companies.filter(company => company.companyName.toLowerCase().includes(filterValue));
    } else {
      return this.companies;
    }
  }

  /**
   * Metodo encargado de filtrar los departamentos por medio del nombre
   */
  private filterProviders(value: any): IApiProveedor[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.providers.filter(provider => provider.proNombre.toLowerCase().includes(filterValue));
    } else {
      return this.providers;
    }
  }

  /**
   * Metodo encargado de filtrar los usuarios por medio del nombre de usuario
   */
  private filterDepartments(value: any): IApiDepartamento[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.departments.filter(department => department.nombre.toLowerCase().includes(filterValue));
    } else {
      return this.departments;
    }
  }

  /**
   * Metodo encargado de filtrar los materiales por medio del nombre
   */
  private filterMaterials(value: any): IApiMaterial[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.materials.filter(material => material.nomGenerico.toLowerCase().includes(filterValue));
    } else {
      return this.materials;
    }
  }

  /**
   * Metodo encargado de filtrar las ciudades por medio del nombre de generico
   */
  private filterCities(value: any): IApiMunicipio[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.cities.filter(city => city.nombre.toLowerCase().includes(filterValue));
    } else {
      return this.cities;
    }
  }

  /**
   * Metodo encargado de filtrar los modulos por medio del nombre
   */
  private filterParentModulesByCompany(value: any): IApiModule[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.parentModules.filter(parentModule => parentModule.modName.toLowerCase().includes(filterValue));
    } else {
      return this.parentModules;
    }
  }

  /**
   * Metodo generico que se encarga de validar el tipo de autocomplete y
   * selecciona el dato a entregar
   */
  selectOption(e: MatAutocompleteSelectedEvent) {
    switch (this.typeAutoComplete) {
      case this.autoCompleteEnum.COMPANY:
        this.selectCompanyOption(e);
        break;
      case this.autoCompleteEnum.USERS:
        this.selectUserOption(e);
        break;
      case this.autoCompleteEnum.PARENT_MODULES:
        this.selectParentModuleOption(e);
        break;
      case this.autoCompleteEnum.COMPANIES_BY_USER:
        this.selectCompanyOption(e);
        break;
      case this.autoCompleteEnum.PROVIDERS:
        this.selectProviderOption(e);
        break;
      case this.autoCompleteEnum.DEPARTMENTS:
        this.selectDepartmentOption(e);
        break;
      case this.autoCompleteEnum.CITIES:
        this.selectCityOption(e);
        break;
      case this.autoCompleteEnum.MATERIALS:
        this.selectMaterialOption(e);
        break;
      case this.autoCompleteEnum.PARENT_MODULES_BY_COMPANY:
        this.selectParentModuleByCompanyOption(e);
        break;
      default:
        break;
    }
  }

  /**
   * Metodo encargado de encontrar la compañia seleccionada y enviarlo
   * al componente padre
   */
  selectCompanyOption(e: MatAutocompleteSelectedEvent) {
    this.selectedCompany = this.companies.find(x => x.companyName == e.option.value);
    this.autocompleteEvent.emit(this.selectedCompany);
  }

  /**
   * Metodo encargado de encontrar el usuario seleccionado y enviarlo
   * al componente padre
   */
  selectUserOption(e: MatAutocompleteSelectedEvent) {
    this.selectedUser = this.users.find(x => x.username == e.option.value);
    this.autocompleteEvent.emit(this.selectedUser);
  }

  /**
   * Metodo encargado de encontrar el modulo padre seleccionado y enviarlo
   * al componente padre
   */
  selectParentModuleOption(e: MatAutocompleteSelectedEvent) {
    this.selectedParentModule = this.parentModules.find(x => x.modName == e.option.value);
    this.autocompleteEvent.emit(this.selectedParentModule);
  }

  /**
   * Metodo encargado de encontrar el proveedor seleccionado y enviarlo
   * al componente padre
   */
  selectProviderOption(e: MatAutocompleteSelectedEvent) {
    this.selectedProvider = this.providers.find(x => x.proNombre == e.option.value);
    this.autocompleteEvent.emit(this.selectedProvider);
  }

  /**
   * Metodo encargado de encontrar el proveedor seleccionado y enviarlo
   * al componente padre
   */
  getProviderSelected(providerName: string) {
    this.selectedProvider = this.providers.find(x => x.proNombre == providerName);
    this.autocompleteEvent.emit(this.selectedProvider);
  }

  /**
   * Metodo encargado de encontrar el modulo padre seleccionado y enviarlo
   * al componente padre
   */
  selectDepartmentOption(e: MatAutocompleteSelectedEvent) {
    this.selectedDepartment = this.departments.find(x => x.nombre == e.option.value);
    this.autocompleteEvent.emit(this.selectedDepartment);
  }

  /**
   * Metodo encargado de encontrar el modulo padre seleccionado y enviarlo
   * al componente padre
   */
  selectCityOption(e: MatAutocompleteSelectedEvent) {
    this.selectedCity = this.cities.find(x => x.nombre == e.option.value);
    this.autocompleteEvent.emit(this.selectedCity);
  }

  /**
   * Metodo encargado de encontrar el material seleccionado y enviarlo
   * al componente padre
   */
  selectMaterialOption(e: MatAutocompleteSelectedEvent) {
    this.selectedMaterial = this.materials.find(x => x.nomGenerico == e.option.value);
    this.autocompleteEvent.emit(this.selectedMaterial);
  }

  /** Metodo encargado de encontrar el modulo padre seleccionado y enviarlo
   * al componente padre
   */
  selectParentModuleByCompanyOption(e: MatAutocompleteSelectedEvent) {
    this.selectedParentModule = this.parentModules.find(x => x.modName == e.option.value);
    this.autocompleteEvent.emit(this.selectedParentModule);
  }

  private requireMatch(control: FormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (this.companies && !this.companies.some(x => x.companyName == selection)) {
      return { requireMatch: true };
    }
    return null;
  }

}
