import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-messages-optional, [app-header-messages-optional]',
  templateUrl: './header-messages-optional.component.html',
  styleUrls: ['./header-messages-optional.component.css']
})
export class HeaderMessagesOptionalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
