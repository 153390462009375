<div class="row">
  <div class="col-xl-12 mx-auto">
    <div class="card border-top border-0 border-4 border-dark">
      <div class="card-body">
        <div class="card-title d-flex align-items-center">
          <div class="card-title d-flex align-items-center">
            <div><i class="bx bx-search-alt me-1 font-22 text-dark"></i></div>
            <h5 class="mb-0 text-dark">{{titulo}}</h5>
          </div>
        </div>
        <div class="row mb-6">
          <div class="col-md-9">
            <mat-form-field>
              <mat-label>Filtrar:</mat-label>
              <input matInput id="filtro" type="text" >
            </mat-form-field>
          </div>
        </div>
        <div id="tablaContainer"></div>
      </div>
      <div class="card-footer">
        <div class="col-12 text-right">
            <!-- Button de Regresar -->
            <button mat-button class="btn btn-danger mx-1" (click)="cerrarDialog()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>
