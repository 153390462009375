<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{data[0]}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>

        <div class="modal-body" >
            <pre>{{ data[1] | json}}</pre>
        </div>

        <div class="modal-footer">
        </div>
    </div>    
</div>

