<!--wrapper-->
<div class="wrapper" [ngClass]="{'nav-collapsed' : getSideBarState()}">

    <!--sidebar wrapper -->
    <app-left-nav></app-left-nav>
    <!--end sidebar wrapper -->

    <!--start header -->
    <app-header></app-header>
    <!--end header -->
    
    <!--start page wrapper -->
    <div class="page-wrapper">
        <div class="page-content-wrapper">
            <div class="page-content">
                <router-outlet></router-outlet>
                <div class="overlay" (click)="toggleSidebar()" [ngClass]="{'show' : getSideBarState()}"></div>
            </div>
        </div>
    </div>
    <!--end page wrapper -->
       
    <!--Start page footer --> 
    <app-footer></app-footer>
    <!--End page footer -->

</div>
<!--end wrapper-->
