<div>
    <div class="modal-header">
        <h5 class="modal-title">{{title}}</h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-xl-12 mx-auto text-center" style="margin-bottom: 1%;">
                <b>{{message}}</b>
            </div>
            <div class="col-xl-12 mx-auto text-center" style="margin-bottom: 1%;">
                <input [formControl]="input" type="hidden" class="form-control" readonly>
                <b>{{document}}</b>
            </div>
            <div class="col-xl-12 mx-auto text-center">
                <b>{{question}}</b>
            </div>       
        </div>     
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(dataMsg.MSG_CANCELED_PROCESS)">
            {{dataMsg.BUTTON_NO}}
        </button>
        <button type="button" class="btn btn-outline-dark" [class.disabled]="input.invalid" [disabled]="input.invalid"
        (click)="activeModal.close(input.value)">
            {{dataMsg.BUTTON_YES}}
        </button>
    </div>
</div>