<!-- wrapper -->
<div class="wrapper">
    <nav class="navbar navbar-expand-lg navbar-light bg-white rounded fixed-top rounded-0 shadow-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="#" [routerLink]="dataGeneral.URL.LOGIN">
                <img [src]="dataGeneral.URL.MAIN_LOGO_IMG" width="140" alt="" />
            </a>
        </div>
    </nav>
    <div class="error-404">
        <div class="container">
            <div class="card py-5">
                <div class="row g-0">
                    <div class="col col-xl-6">
                        <div class="card-body p-5">                            
                            <div class="card text-center">
                                <div class="card-body">
                                    <h2 class="font-weight-bold display-4 text-center text-danger">{{title}}</h2>                                    
                                </div>
                            </div>
                            <div class="text-danger rounded text-center">{{message}}</div>
                            
                            <div class="mt-5 text-center font-weight-bold"> 
                                <a href="javascript:;" [routerLink]="dataGeneral.URL.LOGIN" class="btn btn-outline-dark px-5">
                                    <i class="fadeIn animated bx bx-arrow-from-right"></i>{{dataGeneral.BUTTON.BACK}}
                                </a>                                
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <img [src]="dataGeneral.URL.PAGE_401_IMG" class="rounded mx-auto d-block" alt="">
                    </div>
                </div>
                <!--end row-->
            </div>
        </div>
    </div>
    <div class="bg-white p-3 fixed-bottom border-top shadow">
        <div class="d-flex align-items-center justify-content-between flex-wrap">
            <p class="mb-0">{{dataFooter.TITLES.COPYRIGHT}}</p>
        </div>
    </div>
</div>
