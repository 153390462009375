<!-- wrapper -->
<div class="wrapper">
    <nav class="navbar navbar-expand-lg navbar-light bg-white rounded fixed-top rounded-0 shadow-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="#" routerLink="{{this.authService.getUser ? dataGeneral.URL.USER_HOME: dataGeneral.URL.LOGIN}}">
                <img [src]="dataGeneral.URL.MAIN_LOGO_IMG" width="140" alt="" />
            </a>
        </div>
    </nav>
    <div class="error-404">
        <div class="container">
            <div class="card py-5">
                <div class="row g-0">
                    <div class="col col-xl-6">
                        <div class="card-body p-4">
                            <h1 class="display-1 text-center"><span class="text-primary">4</span><span class="text-danger">0</span><span class="text-success">4</span></h1>
                            <h2 class="font-weight-bold display-4 text-center">{{dataGeneral.UNAUTHORIZED_ACCESS.PAGE_NOT_FOUND}}</h2>
                            <p class="text-center">{{dataGeneral.UNAUTHORIZED_ACCESS.PAGE_NOT_FOUND_DESC}}</p>
                            <div class="mt-5 text-center"> 
                                <a href="javascript:;" [routerLink]="dataGeneral.URL.USER_HOME" *ngIf="this.authService.getUser" class="btn btn-outline-dark px-5">
                                    <i class="fadeIn animated bx bx-home"></i>{{dataGeneral.BUTTON.HOME}}
                                </a>
                                <a href="javascript:;" [routerLink]="dataGeneral.URL.LOGIN" *ngIf="!this.authService.getUser" class="btn btn-outline-dark px-5">
                                    <i class="fadeIn animated bx bx-arrow-from-right"></i>{{dataGeneral.BUTTON.BACK}}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <img [src]="dataGeneral.URL.PAGE_404_IMG" class="rounded mx-auto d-block" alt="">
                    </div>
                </div>
                <!--end row-->
            </div>
        </div>
    </div>
    <div class="bg-white p-3 fixed-bottom border-top shadow">
        <div class="d-flex align-items-center justify-content-between flex-wrap">
            <p class="mb-0">{{dataFooter.TITLES.COPYRIGHT}}</p>
        </div>
    </div>
</div>
