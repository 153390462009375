<div class="page-breadcrumb d-none d-sm-flex align-items-center" [ngClass]="dataBreadcrumb.mbClass ? dataBreadcrumb.mbClass : 'mb-2'">
	<div class="breadcrumb-title pe-3">{{dataBreadcrumb.title}}</div>
	<div class="ps-3">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb mb-0 p-0">
				<li  class="breadcrumb-item"><a href="#" [routerLink]="URL_USER_HOME"><i class="bx bx-home-alt"></i></a>
				</li>
				<ng-container *ngIf="dataBreadcrumb.subBreadcrumb">
					<li *ngFor="let item of dataBreadcrumb.subBreadcrumb" class="breadcrumb-item" [ngClass]="item.class ? item.class : ''" aria-current="page"> 
						<ng-container *ngIf="item.class !== 'active'; else titleActive">
							<a href="#" [routerLink]="[item.path]">{{item.title}}</a>
						</ng-container>
						<ng-template #titleActive>{{item.title}}</ng-template>
					</li>
				</ng-container>
			</ol>
		</nav>
	</div>
</div>
