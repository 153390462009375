<div cdkDropListGroup>
    <div class="example-container">
        <label class="form-label">{{nameItemsAvailables}}</label>
        <div cdkDropList [cdkDropListData]="dropListAvailable" class="example-list" (cdkDropListDropped)="drop($event)">
            <div [ngSwitch]="typeDropList">
                <div *ngSwitchCase="dropListEnum.PARENT_MODULES">
                    <div class="example-box" *ngFor="let item of dropListAvailable" cdkDrag>{{item.modName}}</div>
                </div>
                <div *ngSwitchCase="dropListEnum.ROLES">
                    <div class="example-box" *ngFor="let item of dropListAvailable" cdkDrag>{{item.rolName}}</div>
                </div>
                <div *ngSwitchCase="dropListEnum.CHILD_MODULES_BY_ROLE">
                    <div class="example-box" *ngFor="let item of dropListAvailable" cdkDrag>{{item.modName}}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="example-container">
        <label class="form-label">{{nameItemsSelected}}</label>
        <div cdkDropList [cdkDropListData]="dropListSelected" class="example-list" (cdkDropListDropped)="drop($event)">
            <div [ngSwitch]="typeDropList">
                <div *ngSwitchCase="dropListEnum.PARENT_MODULES">
                    <div class="example-box" *ngFor="let item of dropListSelected" cdkDrag>{{item.modName}}</div>
                </div>
                <div *ngSwitchCase="dropListEnum.ROLES">
                    <div class="example-box" *ngFor="let item of dropListSelected" cdkDrag>{{item.rolName}}</div>
                </div>
                <div *ngSwitchCase="dropListEnum.CHILD_MODULES_BY_ROLE">
                    <div class="example-box" *ngFor="let item of dropListSelected" cdkDrag>{{item.modName}}</div>
                </div>
            </div>
        </div>
    </div>
</div>