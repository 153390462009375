<header>
    <div class="topbar d-flex align-items-center">
        <nav class="navbar navbar-expand">
            
            <!--START Mostrar el icono de menu en pantallas pequeñas-->
            <div class="mobile-toggle-menu"><i (click)="toggleSidebar()" class='bx bx-menu'></i></div>
            <!--END Mostrar el icono de menu en pantallas pequeñas-->
            
            <!--START header search component-->
            <!--<div class="search-bar flex-grow-1" app-header-search></div>-->
            <!--END header search component-->

            <div class="top-menu ms-auto">
                <ul class="navbar-nav align-items-center">
                    <!--START busqueda en version mobile-->
                    <!--<li class="nav-item mobile-search-icon">
                        <a class="nav-link" href="#" (click)="$event.preventDefault()">	<i class='bx bx-search'></i>
                        </a>
                    </li>-->
                    <!--END busqueda en version mobile-->

                    <!--START header tasks component-->
                    <li class="nav-item dropdown dropdown-large" app-header-tasks></li>
                    <!--END header tasks component-->
                    
                    <!--START header alerts optional component-->
                    <!--<li class="nav-item dropdown dropdown-large" app-header-alerts-optional></li>-->
                    <!--END header alerts optional component-->

                    <!--START header messages optional component-->
                    <!--<li class="nav-item dropdown dropdown-large" app-header-messages-optional></li>-->
                    <!--END header messages optional component-->
                </ul>
            </div>
            
            <!--START header user information component-->
            <div class="user-box dropdown" app-header-user></div>
            <!--END header user information component-->
        </nav>
    </div>
</header>
