import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_ROUTES, INTERNAL_ROUTES } from '@data/constants/routes';
import { IApiAuthUsrRol } from '@data/interfaces';
import { IApiRole, IApiRoleResponse } from '@data/interfaces/services/iapi-role.metadata';
import { ApiClass } from '@data/schema/ApiClass.class';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends ApiClass {

  constructor(http: HttpClient,
    private router: Router) {
    super(http);
  }

  /**
   * Get all roles from api
   */
   getAllRoles(): Observable<any> {
    const response = { error: false, msg: '', data: null };
    return this.http.get<IApiRoleResponse>(API_ROUTES.ROLE.LIST)
      .pipe(
        map(r => {
          response.data = r.authRoles;
          return response;
        }),
        catchError(this.error)
      );
  }

  /**
   * Create Role 
   */
  createRole(authRole: IApiRole
  ): Observable <any> {
    //const response = { error: true, msg: ERRORS_VALIDATIONS_CONSTS.LOGIN.ERROR.USER_NO_FOUND, data: null };    
    //Invocar al servicio de autenticacion
    return this.http.post<IApiRole>(API_ROUTES.ROLE.NEW, authRole)
      .pipe (
        map(r => {          
          return r;
        }),
        catchError(this.error)      
      );
  }


  /**
   * Update Role 
   */
  updateRole(authRoles: IApiRole
      ): Observable <any> {
    return this.http.put<IApiRole>(API_ROUTES.ROLE.EDIT, authRoles)
      .pipe (
        map(r => {          
          console.log('response + msg: ' , r);

          // Validar si no retorno un error al momento de autenticar, para redireccionar al panel principal
          if(r){
            this.router.navigateByUrl(INTERNAL_ROUTES.ROLE_LIST);
          }
          return r;
        }),
        catchError(this.error)      
      );
  }

  /**
   * Metodo encargado de consultar la informacion de un rol especifico
   * @param roleId id del rol
   * @returns IApiRoleResponse Objeto con la informacion del rol
   */
  findByRoleId(roleId: string): Observable<any> {
    return this.http.get<IApiRoleResponse>(API_ROUTES.ROLE.FIND_ROLE_BY_ID + '/' + roleId)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
    * Metodo encargado de consultar la informacion de los roles por compañia
    * @param companyId id de la compañia
    * @returns IApiRoleResponse Objeto con la informacion del rol
    */
  findRolesByCompanyId(companyId: number): Observable<any> {
    return this.http.get<IApiRoleResponse>(API_ROUTES.ROLE.FIND_ROLES_BY_COMPANY_ID + '/' + companyId)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
  * Metodo encargado de asignar rol a un usuario especifico
  * @param roleId id del rol
  * @param userId id del usuario
  * @returns 
  */
  addRoleToUser(userRol:IApiAuthUsrRol ) : Observable <any> {
    //Invocar al servicio de creacion de usuario
    return this.http.post<IApiRoleResponse>(API_ROUTES.ROLE.ADD_ROLE_TO_USER, userRol)
      .pipe (
        map(r => {          
          return r;
        }),
      catchError(this.error)      
    );
  }

  /**
    * Metodo encargado de consultar la informacion de los roles por usuario
    * @param userId id del usuario
    * @returns IApiRoleResponse Objeto con la informacion del rol
    */
  findRolesByUser(userId: number): Observable<any> {
    return this.http.get<IApiRoleResponse>(API_ROUTES.ROLE.FIND_ROLES_BY_USER_ID + '/' + userId)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

}
