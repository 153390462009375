import { INTERNAL_ROUTES } from "@data/constants/routes";

export const PROVIDER_CONSTANTS = {
    TITLES: {
        ADMIN_PROVIDERS: 'Admin. Proveedores',
        MANAGEMENT_PROVIDERS: 'Administración Proveedores',
        PROVIDERS: 'Proveedores',
        PROVIDERS_NEW: 'Ingresar Proveedor',
        PROVIDERS_LIST: 'Listado de Proveedores',
        PROVIDERS_EDIT: 'Editar Proveedor',
        PROVIDERS_MATERIALES_EDIT: 'Vincular materiales',
    },
    LIST: {
        BUTTON_NEW: 'Ingresar Proveedor',
        URL_PROVIDERS_NEW: INTERNAL_ROUTES.KATIA_PROVIDERS_NEW,
        SEARCH_PROVIDERES: 'Buscar Proveedores',
        SEARCH_PROVIDERS_STATUS: 'Buscar Proveedores por Estado',
        MSG_CONFIRM_ACTIVATE_PROVIDER : '¿Esta seguro de Activar el Proveedor ## ?',
        MSG_CONFIRM_INACTIVATE_PROVIDER : '¿Esta seguro de Inactivar el Proveedor ## ?',
        UPDATE_PROVIDER_TITLE: 'Actualización Estado',
        UPDATE_PROVIDER_MSG: 'Estado Actualizado Exitosamente para el Proveedor ',  
    },
    PROVIDER_NEW_EDIT: {
        PROVIDER_TITLE: 'Ingresar Proveedor',
        PROVIDER_NEW_TITLE: 'Ingresar Proveedor',
        PROVIDER_EDIT_TITLE: 'Editar Proveedor',
        PROVIDERS_MATERIALES_EDIT_TITLE: 'Vincular materiales',
        PROVIDER_NAME: 'Proveedor',
        NIT: 'NIT',
        CIUDAD: 'Ciudad',
        ADDRESS: 'Dirección',
        PHONENUMBER: 'Telefonos',
        EMAIL: 'Email',
        CONTACT: 'Contacto',
        DESCRIPTION: 'Descripción',
        DEPARTMENT: 'Departamento',
        CITY: 'Municipio',
        BUTTON_REGISTER: 'Registrar',
        BUTTON_EDIT: 'Editar',
        MSG_REGISTER_PROVIDER_TITLE: 'Registrar Proveedor',
        MSG_REGISTER_PROVIDER_DESC: 'Se Registro Exitosamente el Proveedor ',
        MSG_EDIT_PROVIDER_TITLE: 'Actualizar Proveedor',
        MSG_EDIT_PROVIDER_DESC: 'Se Actualizo Exitosamente el Proveedor ',
        MSG_UNIQUE_CODE_GENERAL: '* El Código Único se genera automáticamente al momento de almacenar la información en el sistema.',
        MSG_GET_PROVIDER_TITLE: 'Información Proveedor',
        MSG_GET_PROVIDER_DESC: 'No se encontro información para el Proveedor seleccionado',

    } 
}