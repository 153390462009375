import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_ROUTES } from '@data/constants/routes';
import { IApiDepartamentoResponse, IApiMunicipioResponse } from '@data/interfaces/services/iapi-geographic.metadata';
import { ApiClass } from '@data/schema/ApiClass.class';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeographicService extends ApiClass {
   
  constructor(http: HttpClient,
    private router: Router) {
    super(http);
  }

  /**
   * Get all deparments from api
   */
  getAllDepartments(): Observable<any> {
    return this.http.get<IApiDepartamentoResponse>(API_ROUTES.GEOGRAPHIC.DEPARTMENT.LIST)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
   * Get module by Id from api
   */
  getCitiesByDeparmentId(deparmentId: number): Observable<any> {
      return this.http.get<IApiMunicipioResponse>(API_ROUTES.GEOGRAPHIC.CITIES.FIND_CITIES_BY_DEPARTMENT_ID+'/'+deparmentId)
     .pipe(
       map( r => {       
         return r;
       }),
       catchError(this.error)
     );
   }
}
