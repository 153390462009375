import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { API_ROUTES } from '@data/constants/routes';
import { AuthService } from '@data/services/api/auth.service';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'usersAdmin';

  constructor(private bnIdle: BnNgIdleService,
    private router: Router,
    private authService: AuthService,
    private dialogRef: MatDialog) {
 
  }
 
  // initiate it in your component OnInit
  ngOnInit(): void {
    this.bnIdle.startWatching(3600).subscribe((isTimedOut: boolean) => {
      if(this.authService.isUserLoggedIn()){
        if (isTimedOut) {
          this.authService.logout();
          this.dialogRef.closeAll();
          this.router.navigate([API_ROUTES.AUTH.LOGIN]);
          localStorage.clear();
        }
      }     
    });
  }

}
