<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{dataMsg.TITLE}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>

        <div class="modal-body text-center">
            <p>{{data[0]}}</p>
        </div>

        <div class="modal-footer">
        </div>
    </div>    
</div>
