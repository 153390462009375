import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GENERAL_CONSTANTS } from '@data/constants';
import { API_ROUTES } from '@data/constants/routes';
import { IApiProveedor, IApiProveedorResponse } from '@data/interfaces/services/iapi-provider.metadata';
import { ApiClass } from '@data/schema/ApiClass.class';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProveedorService extends ApiClass {

  constructor(http: HttpClient,
    private router: Router) {
    super(http);
  }

  /**
   * Get all Active Providers
   */
  getAllProviders(): Observable<any> {
    return this.http.get<IApiProveedorResponse>(API_ROUTES.KATIA.PROVIDERS.LIST)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
   * Crear proveedor 
   */
  createProvider(provider: IApiProveedor
  ): Observable<any> {
    //Invocar al servicio de Actualizar 
    return this.http.post<IApiProveedorResponse>(API_ROUTES.KATIA.PROVIDERS.NEW, provider)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  findProviderById(providerId: string): Observable<any> {
    //Invocar al servicio de autenticacion
    return this.http.get<IApiProveedorResponse>(API_ROUTES.KATIA.PROVIDERS.FIND_PROVIDER_BY_ID + '/' + providerId)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
    * Update Provider 
    */
  updateProvider(material: IApiProveedor): Observable<any> {
    //Invocar al servicio de autenticacion
    return this.http.put<IApiProveedorResponse>(API_ROUTES.KATIA.PROVIDERS.EDIT, material)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }


  /**
    * Update Provider 
    */
  updateAsociarMaterial(materiales:any): Observable<any> {
    //Invocar al servicio de autenticacion
    return this.http.post(API_ROUTES.KATIA.PROVIDERS.ASOCIAR_MATERIAL, materiales)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }



  /**
   * Update status provider
   * @param proId 
   * @param proEstado 
   */
  updateStatusProvider(proId: number, proEstado: number) : Observable<any> {
    //Asignar los datos al objeto para actualizar
    const request: IApiProveedor= {} as IApiProveedor;
    request.proId = proId;    
    request.proEstado = (proEstado == GENERAL_CONSTANTS.STATUS.ACTIVE ? GENERAL_CONSTANTS.STATUS.INACTIVE : GENERAL_CONSTANTS.STATUS.ACTIVE);//Asignar el cambio de estado a la inversa
    console.log('updateStatusProvider ->request ' , request);

    //Invocar al servicio de actualizacion de estado
    return this.http.put<IApiProveedorResponse>(API_ROUTES.KATIA.PROVIDERS.UPDATE_STATUS, request)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }


}
