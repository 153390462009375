import { IMAGES_ROUTES } from "@data/constants/routes";

export const AUTH_CONSTANTS = {
    LOGIN: {
        TITLE: 'INICIAR SESIÓN',
        MAIN_LOGO_IMG: IMAGES_ROUTES.MAIN_LOGO_IMG,
        INPUT_EMAIL:'Correo Electrónico(*):',
        INPUT_EMAIL_PLACEHOLDER:'Ingrese Correo Electrónico',
        INPUT_PASSWORD: 'Contraseña(*):',
        INPUT_PASSWORD_PLACEHOLDER: 'Ingrese Contraseña',
        MSG_OLVIDO_PASSWORD: 'Olvido su Contraseña?',
        BUTTON_INGRESAR: 'Ingresar',
    },
    FORGOT_P: {
        TITLE: '¿Olvido su Contraseña?',
        LOGO: IMAGES_ROUTES.FORGOT_PASSWORD_LOGO,
        DESCRIPTION_UNO: 'Ingrese su Correo Electrónico registrado en el sistema',
        DESCRIPTION_DOS: 'para restablecer la contraseña',
        INPUT_EMAIL:'Correo Electrónico(*):',
        INPUT_EMAIL_PLACEHOLDER:'Ingrese Correo Electrónico',
        BUTTON_RETRIEVE: 'Recuperar Contraseña',
        BUTTON_BACK_TO_LOGIN: 'Volver al Login',
        MSG_FORGOT_PASSWORD : '¿Esta seguro de reestablecer la contraseña para el usuario ## ?',
        FORGOT_PASSWORD_TITLE: 'Recuperar Contraseña',
        FORGOT_PASSWORD_OK_MSG: 'Se Envio Exitosamente la nueva contraseña al correo ##.',
        FORGOT_PASSWORD_ERROR_MSG: 'Ocurrio un error al momento de recuperar la contraseña. El correo electrónico ## no existe. Revise la información e intentelo nuevamente.',
    
    },
    USER_COMPANY: {
        TITLE: 'Seleccione una Empresa',
        INPUT_COMPANY:'Empresas(*):',
        INPUT_COMPANY_PLACEHOLDER:'Seleccione una Empresa',
        BUTTON_CHOOSE_COMPANY: 'Seleccionar',
    }
}    