import { Component, OnInit } from '@angular/core';
import { INTERNAL_ROUTES } from '@data/constants/routes';
import { SidebarService } from '@shared/services/sidebar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public URL_COMPANY_LIST: string = INTERNAL_ROUTES.COMPANY_LIST;

  constructor(public sidebarservice: SidebarService) { }

  toggleSidebar() {
    //this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
      this.sidebarservice.setSidebarState(true);
  }

  ngOnInit() {

    /* Search Bar */
    $(document).ready(function () {
        $(".mobile-search-icon").on("click", function () {
            $(".search-bar").addClass("full-search-bar")
        }), 
        $(".search-close").on("click", function () {
            $(".search-bar").removeClass("full-search-bar")
        })
    });

}

}
