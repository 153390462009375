export const IMAGES_ROUTES = {
    //MAIN_LOGO_IMG: 'assets/images/logo-img.png',
    //MAIN_LOGO_ICON: 'assets/images/logo-icon.png',
    MAIN_LOGO_IMG: 'assets/images/logo-bell-franz.jpeg',
    MAIN_LOGO_ICON: 'assets/images/logo-icon-bell-franz.jpeg',    
    FORGOT_PASSWORD_LOGO: 'assets/images/icons/forgot-2.png',
    UNAUTHORIZED_ACCESS_IMG: 'assets/images/errors-images/505-error.png',
    PAGE_404_IMG: 'assets/images/errors-images/404-error.png',
    PAGE_401_IMG: 'assets/images/errors-images/401-error.png',
    PAGE_505_IMG: 'assets/images/errors-images/505-error.png',
    EXCEL_ICON: 'assets/images/reports/excel_icon.png',
    PDF_ICON: 'assets/images/reports/pdf_icon.png',
}