import { INTERNAL_ROUTES } from "@data/constants/routes";

export const ROLE_CONSTANTS = {
    TITLES: {
        ROLE: 'Roles',
        ROLE_LIST: 'Listado Roles',
        ROLE_NEW: 'Crear Rol'
    },
    ROLE_LIST: {
        TITLE: 'Roles',        
        SEARCH_ROLES: 'Buscar Roles',
        BUTTON_NEW: 'Nuevo',
        TH_NAME: 'Nombre',
        TH_DESCRIPTION: 'Descripción',
        TH_ACTIONS: 'Acciones',
        TH_STATUS: 'Estado',
        MSG_ACTIVE: 'Activo',
        MSG_INACTIVE: 'Inactivo',
        MSG_ACTIVATE: 'Activar',
        MSG_INACTIVATE: 'Inactivar',        
        MSG_EDIT: 'Editar',
        MSG_ALERT_ACTIVATE_ROLE : '¿Esta seguro de Activar el Rol ## ?',
        MSG_ALERT_INACTIVATE_ROLE : '¿Esta seguro de Inactivar el Rol ## ?',
        UPDATE_ROLE_TITLE: 'Actualización Estado',
        UPDATE_ROLE_MSG: 'Estado Actualizado Exitosamente para el Rol ',
        URL_ROLE_NEW: INTERNAL_ROUTES.ROLE_NEW,        
        URL_ROLE_EDIT: INTERNAL_ROUTES.ROLE_EDIT,
        URL_ROLE_SUBMODULE_ASSIGN: INTERNAL_ROUTES.ROLE_SUBMODULE_ASSIGN,
    },
    ROLE_NEW_EDIT: {
        TITLE: 'Roles',
        TH_NAME: 'Nombre(*)',
        TH_DESCRIPTION: 'Descripción(*)',
        BUTTON_REGISTER: 'Registrar',
        BUTTON_UPDATE: 'Actualizar',
        BUTTON_BACK: 'Regresar',
        MSG_REGISTER_ROLE_TITLE: 'Registrar Rol',
        MSG_REGISTER_ROLE_DESC: 'Se Registro Exitosamente el Rol ',
        MSG_UPDATE_ROLE_TITLE: 'Actualizar Rol',
        MSG_UPDATE_ROLE_DESC: 'Se Actualizo Exitosamente el Rol ',
        MSG_GET_ROLE_TITLE: 'Información Rol',
        MSG_GET_ROLE_DESC: 'No se encontro información para el Rol',
        MSG_UPDATE_ROLE_NOT_EXIST_DESC: 'El Rol Ingresado no existe en el sistema.', 
    },
    ROLE_SUBMODULE_ASSIGN:{
        INPUT_SUBMODULES_SELECTED:'',
        INPUT_SUBMODULES_AVAILABLES:'',
        INPUT_MODULE:'Modulos',
        BUTTON_REGISTER: 'Registrar',
        BUTTON_BACK: 'Regresar'
    }
}