export enum AutoCompleteEnum{
    DEFAULT = '',
    COMPANY ='Company',
    PARENT_MODULES = 'ParentModules',
    COMPANIES_BY_USER ='CompaniesByUser',
    USERS ='Users',
    VEHICLES = 'Vehicles',
    DRIVERS = 'Drivers',
    PROVIDERS = 'Providers',
    DEPARTMENTS = 'Departments',
    CITIES = 'Cities',
    MATERIALS = 'Materials',
    PARENT_MODULES_BY_COMPANY = 'ParentModulesByCompany',
}