import { INTERNAL_ROUTES } from "@data/constants/routes";

export const USER_CONSTANTS = {
    TITLES: {
        USERS: 'Usuarios',
        PROFILES: 'Roles',
        ADMIN_GENERAL: 'Admin. General',
        ADMIN_USERS: 'Admin. Usuarios',
        MANAGEMENT_USERS: 'Administración Usuarios',
        MANAGEMENT_ROLES: 'Administración Roles',
        USER_LIST: 'Listado de Usuarios',
        ROLES_LIST: 'Listado de Roles',
        CONTROL_PANEL: 'Panel de Control',
        UPDATE_PASSWORD: 'Actualizar Contraseña',
        NEW_USER: 'Registrar Usuario',
        EDIT_USER: 'Editar Usuario',
        GET_PROFILE: 'Perfil de Usuario',
        LOGOUT: 'Cerrar Sesión', 
    },
    UPDATE_PASSWORD: {
        TITLE: 'Actualizar Contraseña',
        INPUT_OLD_PASSWORD: 'Contraseña Actual(*):',
        INPUT_NEW_PASSWORD: 'Nueva Contraseña(*):',
        INPUT_CONFIRM_NEW_PASSWORD: 'Confirme Nueva Contraseña(*):',
        BUTTON_UPDATE: 'Actualizar',
        MSG_CONFIRM_UPDATE_PASSWORD : '¿Esta seguro de cambiar la contraseña de su cuenta?',
        UPDATE_PASSWORD_OK_MSG: 'Se Actualizo Exitosamente la contraseña',
        PASSWORD_NOT_ENTERED: 'Debe ingresar la contraseña que tiene registrada actualmente',
        NEW_PASSWORD_NOT_ENTERED: 'Debe ingresar la nueva contraseña',
        NOT_EXIST_USER: 'No se puede actualizar la contraseña. El usuario no existe en el sistema',
        ERROR_GENERAL: 'Ocurrio un error al momento de actualizar la nueva Contraseña. Revise la información ingresada e intentelo nuevamente',
        CURRENT_PASS_NOT_MATCH_SYSTEM: 'La contraseña actual ingresada, no coincide con la almacenada en el sistema. Revise dicha información e intentelo nuevamente'
    },
    LIST: {
        BUTTON_NEW: 'Nuevo',
        URL_USER_NEW: INTERNAL_ROUTES.PANEL_USER_NEW,
        BUTTON_EDIT: 'Editar',
        URL_USER_EDIT: INTERNAL_ROUTES.PANEL_USER_EDIT,
        SEARCH_USERS: 'Buscar Usuarios',       
        UPDATE_USER_TITLE: 'Actualización Estado',
        UPDATE_USER_MSG: 'Estado Actualizado Exitosamente para el Usuario ',
        MSG_CONFIRM_ACTIVATE_USER : '¿Esta seguro de Activar el usuario ## ?',
        MSG_CONFIRM_INACTIVATE_USER : '¿Esta seguro de Inactivar el usuario ## ?',
        MSG_CONFIRM_RESET_PASSWORD : '¿Esta seguro de reestablecer la contraseña para el usuario ## ?',
        RESET_PASSWORD_TITLE: 'Reestablecer Contraseña',
        RESET_PASSWORD_OK_MSG: 'Se Envio Exitosamente la nueva contraseña al correo ##, para el Usuario ',
        RESET_PASSWORD_ERROR_MSG: 'Ocurrio un error al momento de reestablecer la contraseña. El correo electronico ## no existe. Revise la información e intentelo nuevamente.',
    },
    NEW_EDIT: {
        URL_USER_LIST: INTERNAL_ROUTES.PANEL_USER_LIST,
        TITLE_BASIC_INFORMATION: 'Información Básica',
        INPUT_DOCUMENT: 'N° Documento(*)',
        INPUT_DOCUMENT_TYPE: 'Tipo Documento(*)',
        INPUT_FIRSTNAME: 'Nombres(*)',
        INPUT_LASTNAME: 'Apellidos(*)',
        INPUT_USERNAME: 'Usuario(*)',
        INPUT_AVATAR: 'Foto Perfil',
        INPUT_EMAIL:'Correo Electrónico(*):',
        BUTTON_REGISTER: 'Registrar',
        BUTTON_UPDATE: 'Actualizar',
        BUTTON_BACK: 'Regresar',
        MSG_REGISTER_USER_TITLE: 'Registrar Usuario',
        MSG_REGISTER_USER_DESC: 'Se Registro Exitosamente el Usuario ',
        MSG_UPDATE_USER_TITLE: 'Actualizar Usuario',
        MSG_UPDATE_USER_DESC: 'Se Actualizo Exitosamente el Usuario ',
        MSG_GET_USER_TITLE: 'Información Usuario',
        MSG_GET_USER_DESC: 'No se encontro información para el usuario',
        MSG_ASSING_USER_TITLE: 'Asignar Usuario',
        MSG_ASSING_USER_DESC: 'Se Asigno Exitosamente el Usuario ',
    },
    USER_ROLE_ASSIGN: {
        TITLE_BASIC_INFORMATION: 'Asignar Rol',
        SELECT_ROLES: 'Seleccione un rol',
        INPUT_ROLES_SELECTED:'Roles Seleccionados',
        INPUT_ROLES_AVAILABLES:'Roles Disponibles',
        BUTTON_ASSIGN: 'Asignar',
        BUTTON_CANCEL: 'Cancelar',
        MSG_ASSIGN_ROLE_USER_TITLE: 'Asignar Rol',
        MSG_ASSIGN_ROLE_USER_DESC: 'Se Registro el rol exitosamente ',
        URL_USER_LIST: INTERNAL_ROUTES.PANEL_USER_LIST,
    }
    
}