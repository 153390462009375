import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ERRORS_VALIDATIONS_CONSTS, FOOTER_CONSTANTS, GENERAL_CONSTANTS } from '@data/constants';

@Component({
  selector: 'app-page401',
  templateUrl: './page401.component.html',
  styleUrls: ['./page401.component.scss']
})
export class Page401Component implements OnInit {
  
  public dataFooter = FOOTER_CONSTANTS;
  public dataGeneral = GENERAL_CONSTANTS.SERVER;
  title: string;
  message: string;

  constructor(
    private router: Router
  ) { 
    this.message = '', this.title = '';
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {validation: string};
    this.showMessage(state.validation);   
  }
  /**
   * metodo encargado de realizar la validacion del tipo de regla de acceso y asi determina que mensaje mostrar en pantalla
   * @param validation 
   */
  showMessage(validation: string) {
    this.title = this.dataGeneral.UNAUTHORIZED_ACCESS.PAGE_UNAUTHORIZED;
    switch (validation) {
      case ERRORS_VALIDATIONS_CONSTS.LOGIN.ACCESS_RULES.USER_UNASSIGNED_A_COMPANY:
        this.message = this.dataGeneral.UNAUTHORIZED_ACCESS.USER_UNASSIGNED_A_COMPANY_DESC;
        break;
      case ERRORS_VALIDATIONS_CONSTS.LOGIN.ACCESS_RULES.USER_INACTIVE_IN_COMPANY:
        this.message = this.dataGeneral.UNAUTHORIZED_ACCESS.USER_INACTIVE_IN_COMPANY_DESC;
        break; 
      default:
        break;
    }
  }

  ngOnInit(): void {    
  }
}
