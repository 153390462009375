export enum EnumServiceResponseCodes {
    success = '0',//Transaccion Exitosa
    generalError = '1',
    system_not_avalilable = '2',
    no_data_found = '3',
    companit_asociate_user_not_exist = 4,//The company asociated with the user does not exist
    email_exist = '5',//The email exists in the system
    username_exist = '6',//The user exists in the system
    user_not_exit = '7',//The user does not exist in the system
    company_not_exit = '8',//The company does not exist in the system
    email_not_exist = '9',//The email does not exist in the system
    company_exist = '10',//The company exists in the system
    current_password_not_match_system  = '18',//The current password does not match
    new_password_not_entered  = '19',//The new password has not been entered
    password_not_entered  = '20',//The current password has not been entered
    user_exist_in_company  = '39',//The company asociated with the user exists in the system
}