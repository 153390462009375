import { INTERNAL_ROUTES } from "@data/constants/routes";

export const MATERIAL_CONSTANTS = {
    TITLES: {
        ADMIN_MATERIALS: 'Admin. Materiales',
        MANAGEMENT_MATERIALS: 'Administración Materiales',
        MATERIALS: 'Materiales',
        MATERIALS_NEW: 'Ingresar Material',
        MATERIALS_LIST: 'Listado de Materiales',
        HISTORICAL_MATERIALS: 'Historico Materiales',
        MATERIALS_EDIT: 'Editar Material',
    },
    LIST: {
        BUTTON_NEW: 'Ingresar Material',
        URL_MATERIALS_NEW: INTERNAL_ROUTES.KATIA_MATERIALS_NEW,
        SEARCH_MATERIALES: 'Buscar Materiales',
        SEARCH_MATERIALS_STATUS: 'Buscar por Estado',
        SEARCH_HISTORICAL_MATERIALES: 'Buscar Historico Materiales',
        MSG_CONFIRM_ACTIVATE_MATERIAL : '¿Esta seguro de Activar el Material ## ?',
        MSG_CONFIRM_INACTIVATE_MATERIAL : '¿Esta seguro de Inactivar el Material ## ?',
        UPDATE_MATERIAL_TITLE: 'Actualización Estado',
        UPDATE_MATERIAL_MSG: 'Estado Actualizado Exitosamente para el Material ',
    },
    MATERIAL_NEW_EDIT: {
        GENERIC_NAME: 'Nombre Generico',
        COMMERCIAL_NAME: 'Nombre Comercial',
        CONSECUTIVE: 'Consecutivo',
        UNIQUE_CODE: 'Código Único',
        OBSERVATIONS: 'Observaciones',
        DESCRIPTION: 'Descripcion',
        TYPE_MATERIAL: 'Tipo Material',
        AMOUNT: 'Cantidad',
        UNIT_MEASEURE: 'Unidad de Medida',
        LOT: 'Lote',
        PROVIDER:'Proveedor',
        GROSS_WEIGHT: 'Peso Bruto',
        NET_WEIGHT: 'Peso Neto',
        REGISTRATION_DATE: 'Fecha de Ingreso',
        EXPIRATION_DATE: 'Fecha Expiración',
        NEW_ANALYSIS_DATE: 'Fecha Reanalisis',
        ASPECTO: 'Aspecto',
        COLOR: 'Color',
        OLOR: 'Olor',
        PH: 'pH',
        DENSIDAD: 'Densidad',
        VISCOSIDAD: 'Viscosidad',
        SOLUBILIDAD: 'Solubilidad',
        G_ESPECIFICA: 'G. Específica',
        INDICE_REFRACCION: 'Índice Refracción',
        GRADO_ALCOHOLICO: 'Grado Alcohólico',
        FUNCIONALIDAD: 'Funcionalidad',
        PUNTO_FUSION: 'Punto Fusión',
        ANALISIS_MICRO: 'Índice de Acidez',
        BUTTON_REGISTER: 'Registrar',
        BUTTON_EDIT: 'Editar',
        BUTTON_BACK: 'Regresar',
        BUTTON_BACK_MATERIAL_LIST: INTERNAL_ROUTES.KATIA_MATERIALS_LIST,
        MSG_REGISTER_MATERIAL_TITLE: 'Registrar Material',
        MSG_REGISTER_MATERIAL_DESC: 'Se Registro Exitosamente el Material ',
        MSG_EDIT_MATERIAL_TITLE: 'Actualizar Material',
        MSG_EDIT_MATERIAL_DESC: 'Se Actualizo Exitosamente el Material ##1 del Proveedor ##2',
        MSG_UNIQUE_CODE_GENERAL: '* El Código Único se genera automáticamente al momento de almacenar la información en el sistema.',
        MSG_GET_MATERIAL_TITLE: 'Información Material',
        MSG_GET_MATERIAL_DESC: 'No se encontro información para el material seleccionado',

    }
}
