import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MODAL_CONSTANTS } from '@data/constants';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  
  public dataMsg = MODAL_CONSTANTS.CONFIRMATION;
  constructor(
    public dialogo: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[]
  ) { }
  
  /**
   * Cerrar el Modal
   */
  closeModal(): void {
    this.dialogo.close(false);
  }
  /**
   * Confirmacion del Modal
   */
  confirmModal(): void {
    this.dialogo.close(true);
  }

  ngOnInit(): void {
  }

}
