import { Component, OnInit } from '@angular/core';
import { INTERNAL_ROUTES } from '@data/constants/routes';

@Component({
  selector: 'app-header-alerts-optional, [app-header-alerts-optional]',
  templateUrl: './header-alerts-optional.component.html',
  styleUrls: ['./header-alerts-optional.component.css']
})
export class HeaderAlertsOptionalComponent implements OnInit {

  public URL_COMPANY_LIST: string = INTERNAL_ROUTES.COMPANY_LIST;

  constructor() { }

  ngOnInit(): void {
  }

}
