import { Component, OnInit } from '@angular/core';
import { FOOTER_CONSTANTS } from '@data/constants';
import { AuthService } from '@data/services/api/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public data = FOOTER_CONSTANTS;
  public dateNow = new Date();
  
  constructor(
    public authService: AuthService
  ) { }

  ngOnInit() {
    /* Back To Top */
    $(document).ready(function(){ 
         $(window).on("scroll", function(){              
            if ($(this).scrollTop()! > 300) { 
                $('.back-to-top').fadeIn(); 
            } else { 
                $('.back-to-top').fadeOut(); 
            } 
         }); 

         $('.back-to-top').on("click", function(){ 
             $("html, body").animate({ scrollTop: 0 }, 600); 
             return false; 
         }); 
     });	   
         
 }

}
