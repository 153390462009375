<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{dataMsg.TITLE}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>

        <div class="modal-body text-center">
            <p>{{data[0]}}</p>
        </div>

        <div class="modal-footer">
            <button mat-button (click)="closeModal()" class="btn btn-secondary me-2">{{dataMsg.BUTTON_NO}}</button>
            <button mat-button (click)="confirmModal()" class="btn btn-primary" cdkFocusInitial>{{dataMsg.BUTTON_YES}}</button>
        </div>
    </div>    
</div>