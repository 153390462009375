import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GENERAL_CONSTANTS } from '@data/constants';
import { API_ROUTES, INTERNAL_ROUTES } from '@data/constants/routes';
import { IApiCompany, IApiCompanyModule, IApiCompanyResponse, IApiModule, IApiModuleResponse } from '@data/interfaces';
import { ApiClass } from '@data/schema/ApiClass.class';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends ApiClass {
   
  constructor(http: HttpClient,
    private router: Router) {
    super(http);
  }

  /**
   * Get all companies from api
   */
  getAllCompanies(): Observable<any> {
    return this.http.get<IApiCompanyResponse>(API_ROUTES.COMPANY.LIST)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
   * Metodo encargado de consultar la informacion de una compañia especifica
   * @param companyId id de la empresa
   * @returns IApiCompanyResponse Objeto con la informacion de la compañia
   */
  findByCompanyId(companyId: string): Observable<any> {
    return this.http.get<IApiCompanyResponse>(API_ROUTES.COMPANY.FIND_COMPANY_BY_ID+'/'+companyId)
   .pipe(
     map( r => {       
       return r;
     }),
     catchError(this.error)
   );
 }

  /**
   * Create company 
   */
  createCompany(
    data: {
      companyName: number;
      companyDesc: string;
      companyAddress: string;
      companyBusinessName: string;
      companyBusinessId: string;
    }
  ): Observable <any> {
    //const response = { error: true, msg: ERRORS_VALIDATIONS_CONSTS.LOGIN.ERROR.USER_NO_FOUND, data: null };    
    //Invocar al servicio de autenticacion
    return this.http.post<IApiCompanyResponse>(API_ROUTES.COMPANY.NEW, data)
      .pipe (
        map(r => {   
          return r;
        }),
        catchError(this.error)      
      );
  }
 

  /**
   * Metodo encargado de actualizar un usuario para una compañia especifica
   * @param data informacion del usuario que se desea actualizar
   * @returns 
   */
  updateCompany(data: any) : Observable <any> {
    //Invocar al servicio de actualizacion
    return this.http.put<IApiCompanyResponse>(API_ROUTES.COMPANY.EDIT, data)
      .pipe (
        map(r => {          
          return r;
        }),
      catchError(this.error)      
    );
  }

  /**
   * Get all modules by company from api
   */
   getModulesByCompany(companyId: number): Observable<any> {
    return this.http.get<IApiCompanyResponse>(API_ROUTES.COMPANY.MODULES_BY_COMPANY+`/${companyId}`)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }


  /**
   * Create createModuleToCompany 
   */
  createModuleToCompany(
    data: {
      compModId: number;
      authCompany: IApiCompany;
      authModules: IApiModule;
      status: number
    }
  ): Observable <any> {
    //const response = { error: true, msg: ERRORS_VALIDATIONS_CONSTS.LOGIN.ERROR.USER_NO_FOUND, data: null };    
    //Invocar al servicio de autenticacion
    return this.http.post<IApiCompanyResponse>(API_ROUTES.COMPANY.ASSIGN_MODULE, data)
      .pipe (
        map(r => {          
          return r;
        }),
        catchError(this.error)      
      );
  }

  updateStatusCompany(companyRequest: IApiCompany) : Observable <any> {  
    companyRequest.companyStatus = (companyRequest.companyStatus == GENERAL_CONSTANTS.STATUS.ACTIVE ? GENERAL_CONSTANTS.STATUS.INACTIVE : GENERAL_CONSTANTS.STATUS.ACTIVE);//Asignar el cambio de estado a la inversa
    //console.log('updateStatusCompany ' , companyRequest);

    //Invocar al servicio de actualizacion del estado
    return this.http.put<IApiCompanyResponse>(API_ROUTES.COMPANY.UPDATE_STATUS, companyRequest)
      .pipe (
        map(r => {          
          return r;
        }),
      catchError(this.error)      
    );
  }

  /**
   * Get all modules by company from api
   */
  // getModulesByUserAndCompany(userId: number, companyId: number): Observable<any> {
  //   return this.http.get<IApiModuleResponse>(API_ROUTES.COMPANY.MODULES_BY_USER_AND_COMPANY+`/${userId}/companyId/${companyId}`)
  //     .pipe(
  //       map(r => {
  //         return r;
  //       }),
  //       catchError(this.error)
  //     );
  // }

}
