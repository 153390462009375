import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GENERAL_CONSTANTS } from '@data/constants';
import { API_ROUTES} from '@data/constants/routes';
import { IApiHistoricoMaterialResponse, IApiMaterial, IApiMaterialResponse, IApiUser } from '@data/interfaces';
import { IApiProveedor } from '@data/interfaces/services/iapi-provider.metadata';
import { ApiClass } from '@data/schema/ApiClass.class';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MaterialService extends ApiClass {

  constructor(http: HttpClient,
    private router: Router) {
    super(http);
  }


  getAllMaterials(): Observable<any> {
    const response = { error: false, msg: '', data: [] };
    return this.http.get<IApiMaterialResponse>(API_ROUTES.KATIA.MATERIALS.LIST)
      .pipe(
        map(r => {
          response.data = r.materiales;
          return response;
        }),
        catchError(this.error)
      );
  }

  getAllMaterialsSinAsociar(proveedor: string): Observable<any> {
    const response = { error: false, msg: '', data: [] };
    return this.http.get<IApiMaterialResponse>(API_ROUTES.KATIA.MATERIALS.LIST_SIN_ASOCIAR+"/"+proveedor)
      .pipe(
        map(r => {
          response.data = r.materiales;
          return response;
        }),
        catchError(this.error)
      );
  }

  getAllMaterialesProveedor(proveedor: string): Observable<any> {
    const response = { error: false, msg: '', data: [] };
    return this.http.get<IApiMaterialResponse>(API_ROUTES.KATIA.MATERIALS.LIST_PROVEEDOR+"/"+proveedor)
      .pipe(
        map(r => {
          response.data = r.materiales;
          return response;
        }),
        catchError(this.error)
      );
  }

  eliminarMaterialProveedor(body): Observable<any> {
    return this.http.post<any>(API_ROUTES.KATIA.MATERIALS.ELIMINAR_MATERIAL_PROVEEDOR, body)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  

  /**
   * Create Material 
   */
  createMaterial(material: IApiMaterial): Observable<any> {
    //Invocar el servicio
    return this.http.post<IApiMaterialResponse>(API_ROUTES.KATIA.MATERIALS.NEW, material)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
   * Metodo encargado de obtener todo el listado del historico de materiales registrados
   * @returns 
   */
  getAllHistoricalMaterials(): Observable<any> {
    const response = { error: false, msg: '', data: [] };
    return this.http.get<IApiHistoricoMaterialResponse>(API_ROUTES.KATIA.MATERIALS_HISTORICAL.LIST)
      .pipe(
        map(r => {
          response.data = r.historicos;
          return response;
        }),
        catchError(this.error)
      );
  }

  findMaterialById(materialId: string): Observable<any> {
    //Invocar al servicio de consultar material
    return this.http.get<IApiMaterialResponse>(API_ROUTES.KATIA.MATERIALS.FIND_MATERIAL_BY_ID+'/'+materialId)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
   * Update Material 
   */
  updateMaterial(materialUpdate: IApiMaterial): Observable<any> {
    //Invocar al servicio de actualizacion
    return this.http.put<IApiMaterialResponse>(API_ROUTES.KATIA.MATERIALS.EDIT, materialUpdate)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }
  
    /**
     * Metodo encargado de actualizar el estado de un material
     * @param idMaterial id del material
     * @param idUser id del usuario que actualiza el registro
     * @param estado estado actual del material
     * @returns 
     */
  updateStatusMaterial(idMaterial: number, idUser: number, estado: number): Observable<any> {
    //Asignar los datos al objeto para actualizar
    const request: IApiMaterial = {} as IApiMaterial;
    request.id = idMaterial;
    request.authUser = {} as IApiUser;
    request.authUser.userId = idUser;
    request.estado = (estado == GENERAL_CONSTANTS.STATUS.ACTIVE ? GENERAL_CONSTANTS.STATUS.INACTIVE : GENERAL_CONSTANTS.STATUS.ACTIVE);//Asignar el cambio de estado a la inversa
    //console.log('updateStatusMaterial ->request ' , request);

    //Invocar al servicio de actualizacion de estado
    return this.http.put<IApiUser>(API_ROUTES.KATIA.MATERIALS.UPDATE_STATUS, request)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  findMaterialByProvider(provider: IApiProveedor): Observable<any> {
    //Invocar al servicio de autenticacion
    return this.http.get<IApiMaterialResponse>(API_ROUTES.KATIA.MATERIALS.FIND_MATERIAL_BY_PROVIDER+'/'+provider.proId)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }
}
