import { INTERNAL_ROUTES } from "@data/constants/routes";

export const PROGRAMACION_CONSTANTS = {
  TITLES:{
    PROGRAMACION: 'Programación',
    CRONOGRAMA_NEW: 'Cronograma Semanal',
    LIST_ALL_CRONOGRAMA: 'Listado de cronograma',
    ADD_PRODUCTOS: 'Agregar Producto',
    PLANEACION_LIST:'Inventario',
  },
  LIST:{
    URL_CRONOGRAMA_NEW: INTERNAL_ROUTES.KATIA_CRONOGRAMA_NEW,
    BUTTON_CRONOGRAMA_NEW: 'Agregar Cronograma',
    SEARCH_CRONOGRAMA: 'Filtrar cronograma',
    MSG_CONFIRM_INACTIVATE_ORDEN_CRONOGRAMA: 'Inactivar orden del Cronograma',
    MSG_CONFIRM_ACTIVATE_ORDEN_CRONOGRAMA: 'Activar Orden del Cronograma',
    UPDATE_CRONOGRAMA_TITLE:'Actulizacion CronoCronograma',
    UPDATE_CRONOGRMA_MSG: 'La orden del producto ## ha sido eliminada',
    URL_PLANEACION_NEW: INTERNAL_ROUTES.KATIA_PLANEACION_NEW,
    BUTTON_PLANEACION_NEW: 'Agregar Planeación',
    BUTTON_PRINT: 'Imprimir',
    URL_PLANEACION_LIST:INTERNAL_ROUTES.KATIA_PLANEACION_LIST,
    BUTTON_CRONOGRAMA_GESTION: 'Gestionar Cronograma'
  },
  NEW_EDIT:{
    FECHA_INICIAL:'Fecha Inicial',
    FECHA_FINAL:'Fecha Final',
    TITULO: 'Titulo',
    DESCRIPCION:'Descripción',
    MSG_ADD_CRONOGRAMA_TITLE: 'Agregar Cronograma',
    MSG_ADD_CRONOGRAMA_DESC: 'Cronograma Registrado con exito',

    ORDEN:'Orden produccion',
    FECHA:'Fecha cronograma',
    HORA: 'Hora cronograma',
    OBSERVACION: 'Observacion',
    TIPO_OPERACION: 'Tipo de operacion',
    OPERACION_SEMISOLIDOS: 'Operacion Semisolidos',
    BUTTON_BACK: 'Atrás',
    BUTTON_GUARDAR: 'Agregar Orden a Cronograma',
    MSG_ADD_CRONOGRAMA_ORDEN_TITLE: 'Agregar Orden Cronograma',
    MSG_ADD_CRONOGRAMA_ORDEN_DESC: 'Orden agregada con exito',
    BUTTON_EDITAR: 'Editar',

    ANIO: 'Año',
    MES: 'Mes',
    PRODUCTO: 'Producto',
    PRESENTACION: 'Presentación',
    CONSUMO_PROMEDIO: 'Consumo Promedio',
    CONSUMO_PASADO: 'Consumo Pasado',
    EXISTENCIAS_BODEGA: 'Existencias Bodega',
    EXISTENCIAS_PRODUCCION: 'Existencias Producción',
    BUTTON_GUARDAR_PLANEACION: 'Agregar',
    MSG_ADD_PLANEACION_TITLE: 'Agregar Producto a Planeación',
    MSG_ADD_PLANEACION_DESC: 'Producto agregado con exito',
    MSG_UPDATE_PLANEACION_TITLE: 'Actualizar Producto a Planeación',
    MSG_UPDATE_PLANEACION_DESC: 'Producto actualizado con exito',

  },
  STATUS:{
    ACTIVE: 1,
    MSG_ACTIVE: 'Activo',
    INACTIVE: 0,
    MSG_INACTIVE: 'Inactivo',
  },
  TABLES:{
    TH_FECHA_INICIAL: 'FECHA INICIAL',
    TH_FECHA_FINAL: 'FECHA FINAL',
    TH_TITULO: 'TITULO',
    TH_DESCRIPCION: 'DESCRIPCION',

    TH_ID_ORDEN:'ORDEN',
    TH_PRODUCTO:'PRODUCTO',
    TH_LOTE:'LOTE',
    TH_FECHA_CRONOGRAMA:'FECHA CRONOGRAMA',
    TH_TIPO_OPERACION:'TIPO OPERACION',
    TH_OBSERVACION:'OBSERVACION',

    TH_PRESENTACION: 'PRESENTACION',
    TH_CONSUMO_PROMEDIO: 'CONS. PROMEDIO',
    TH_CONSUMO_PASADO: 'CONS. PASADO',
    TH_EXISTENCIAS_BODEGA: 'EXIST. BODEGA',
    TH_EXISTENCIAS_PRODUCCION: 'EXIST. PRODUCCION',
    TH_DIAS_INVENTARIO: 'DIAS INVENTARIO'
  }

}
