<div class="notifications">
	<div *ngFor="let notification of notifications" class="notification" [ngClass]="className(notification)">
		<ng-container *ngTemplateOutlet="notificationTpl;context:{notification:notification}"></ng-container>
	</div>
</div>

<ng-template #notificationTpl let-notification="notification">

	<div class="title" fxLayout="row" fxLayoutAlign="space-between center">
		<div>{{notification.title}}</div>
		<button mat-icon-button 
            (click)="close(notification)"
            [matTooltip]="data.TOOLTIP_CLOSE"
            matTooltipPosition="before">
        <mat-icon>cancel</mat-icon>
      </button>
	</div>

	<div class="message text-center">{{notification.message}}</div>

</ng-template>

