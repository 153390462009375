<!-- Sidebar Content starts -->
<div class="sidebar sidebar-wrapper">
    <!-- Sidebar Header starts -->
    <div class="sidebar-header">
        <div>
            <a [routerLink]="URL_USER_HOME">
                <ng-container *ngIf="this.authService.getUser.authUserComp != null && this.authService.getUser.authUserComp.length > 0; else logoCompanyDefaultImg">
                    <img src="{{this.authService.getUser.authUserComp[0].authCompany.compavatar ? 
                        this.authService.getUser.authUserComp[0].authCompany.compavatar: MAIN_LOGO_ICON}}" 
                        class="logo-icon" alt="logo icon" [routerLink]="URL_USER_HOME">
                </ng-container>
                <ng-template #logoCompanyDefaultImg> 
                    <img [src]="MAIN_LOGO_ICON" class="logo-icon" alt="logo icon" [routerLink]="URL_USER_HOME">
                </ng-template>
            </a>
        </div>
        <div class="">
            <h4 class="logo-text">
                <a [routerLink]="URL_USER_HOME">
                    <ng-container *ngIf="this.authService.getUser.authUserComp != null && this.authService.getUser.authUserComp.length > 0">
                        {{this.authService.getUser.authUserComp[0].authCompany.companyName}}
                    </ng-container>
                </a>
            </h4>
        </div>
        <div class="toggle-icon ms-auto"><i (click)="toggleSidebar()" class='bx bx-arrow-to-left'></i>
        </div>
    </div>
    <!-- Sidebar Header Ends -->
    
    <!--navigation-->
    <div class="sidebar-content" [perfectScrollbar] app-navigation></div>    
    <!--end navigation-->

</div>
