import { INTERNAL_ROUTES } from "@data/constants/routes";

export const COMPANY_CONSTANTS = {
    TITLES: {
        ADMIN_COMPANY: 'Admin. Empresas',
        COMPANY: 'Empresas',
        COMPANY_LIST: 'Listado Empresas',
        COMPANY_NEW: 'Crear Empresa',
        COMPANY_EDIT: 'Editar Empresa'
    },
    COMPANY_LIST: {
        TITLE: 'Empresa',        
        BUTTON_NEW: 'Nuevo',
        BUTTON_EDIT: 'Editar',
        SEARCH_COMPANIES: 'Buscar Empresas', 
        URL_COMPANY_NEW: INTERNAL_ROUTES.COMPANY_NEW,
        URL_COMPANY_EDIT: INTERNAL_ROUTES.COMPANY_EDIT,
        URL_COMPANY_LIST_USERS: INTERNAL_ROUTES.COMPANY_LIST_USERS,
        MSG_CONFIRM_ACTIVATE_COMPANY : '¿Esta seguro de Activar la Empresa ## ?',
        MSG_CONFIRM_INACTIVATE_COMPANY : '¿Esta seguro de Inactivar la Empresa ## ?',
        UPDATE_COMPANY_TITLE: 'Actualización Estado',
        UPDATE_COMPANY_MSG: 'Estado Actualizado Exitosamente para la Empresa ',
    },
    COMPANY_ASSIGN_MODULE: {
        TITLE: 'Modulos por Empresa',
        AVAILABLE_MODULES: 'Modulos Disponibles',
        SELECTED_MODULES: 'Modulos Seleccionados',
        ASSIGN_BUTTON: 'Asignar',
    },
    COMPANY_NEW_EDIT: {
        TITLE_GENERAL_INFORMATION: 'Información General',
        INPUT_AVATAR: 'Logo',
        INPUT_DESCRIPTION: 'Descripción',
        INPUT_COMPANY_NAME: 'Nombre Empresa(*)',
        INPUT_LEGAL_NAME: 'Nombre Legal(*)',     
        INPUT_ADDRESS: 'Dirección(*)',
        INPUT_LEFT_COLOR: 'Color Menu Izquierdo',
        INPUT_HEADER_COLOR: 'Color Menu Superior',
        INPUT_NIT: 'NIT(*)',
        BUTTON_NEW: 'Registrar',
        BUTTON_EDIT: 'Editar',
        BUTTON_BACK: 'Regresar',
        MSG_REGISTER_COMPANY_TITLE: 'Registrar Empresa',
        MSG_REGISTER_COMPANY_DESC: 'Se Registro Exitosamente el Empresa ',
        MSG_GET_COMPANY_TITLE: 'Información Empresa',
        MSG_GET_COMPANY_DESC: 'No se encontro información para la Empresa Ingresada',
        MSG_UPDATE_COMPANY_TITLE: 'Actualizar Empresa',
        MSG_UPDATE_COMPANY_DESC: 'Se Actualizo Exitosamente la Empresa ',
        MSG_UPDATE_COMPANY_NOT_EXIST_DESC: 'La Empresa Ingresada no existe en el sistema.',  
    }
}