import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-json-modal',
  templateUrl: './json-modal.component.html',
  styleUrls: ['./json-modal.component.scss']
})
export class JsonModalComponent implements OnInit {
  
  constructor(
    public dialogo: MatDialogRef<JsonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[]
  ) { }

  /**
   * Cerrar el Modal
   */
  closeModal(): void {
    this.dialogo.close(false);
  }
  /**
   * Confirmacion del Modal
   */
  confirmModal(): void {
    this.dialogo.close(true);
  }

  ngOnInit(): void {
  }

}
