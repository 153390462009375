import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MODAL_CONSTANTS } from '@data/constants';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss']
})
export class InformationModalComponent implements OnInit {

  public dataMsg = MODAL_CONSTANTS.INFORMATION;

  constructor(
    public dialogo: MatDialogRef<InformationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[]
  ) { }

  /**
   * Cerrar el Modal
   */
  closeModal(): void {
    this.dialogo.close(false);
  }
  /**
   * Confirmacion del Modal
   */
  confirmModal(): void {
    this.dialogo.close(true);
  }

  ngOnInit(): void {
  }

}
