import { INTERNAL_ROUTES } from "@data/constants/routes";

export const PRODUCTION_CONSTANTS = {
    TITLES: {
        PRODUCTION: 'Producción',
        PRODUCTION_ALL_PRODUCT_SHEETS: 'Ver Ficha de Productos',
        PRODUCTION_PRODUCT_SHEETS: 'Fichas de Productos',
        PRODUCTION_LIST_ALL_ORDER: 'Ver Ordenes Producción',
        PRODUCTION_NEW_ORDER: 'Orden Producción',
        //PRODUCTION_PRODUCT_SHEETS: 'Fichas de Productos',
        PRODUCT_SHEET_VIEW: 'Ver Ficha Producto',
        PRODUCCION_ORDER_EDIT: 'Editar Orden Produccion',
    },
    LIST: {
        BUTTON_NEW: 'Ingresar Producto',
        URL_PRODUCT_NEW: INTERNAL_ROUTES.KATIA_PRODUCTION_NEW,
        URL_ORDER_NEW: INTERNAL_ROUTES.KATIA_PRODUCTION_NEW_ORDER,
        SEARCH_PRODUCTS: 'Buscar Productos',
        MSG_CONFIRM_APPROVE_ORDER : '¿Esta seguro de aprobar la orden ## ?',
        APPROVE_ORDER_TITLE: 'Aprobación de Orden',
        APPROVE_ORDER_MSG: 'Orden Aprobada Exitosamente',
        MSG_CONFIRM_INACTIVATE_PRODUCT: '¿Esta seguro de inactivar la el producto ## ?',
        MSG_CONFIRM_ACTIVATE_PRODUCT: '¿Esta seguro de activar la el producto ## ?',
        UPDATE_PRODUCT_TITLE: 'Inactivar Producto',
        UPDATE_PRODUCT_MSG:'Producto ## inactivado',
    },
    STATUS: {
        ACTIVE: 1,
        MSG_ACTIVE: 'Activo',
        INACTIVE: 2,
        MSG_INACTIVE: 'Inactivo',
        APPROVE:1,
        MSG_APPROVE: 'Aprobado',
        PENDING_APPROVE:0,
    },
    NEW: {
        BUTTON_BACK: 'Regresar',
        BUTTON_GUARDAR: 'Guardar',
        URL_PRODUCT_LIST: INTERNAL_ROUTES.KATIA_PRODUCTION_LIST_ALL_PRODUCT_SHEETS,
        MSG_REGISTER_PRODUCT_TITLE: 'Crear Producto',
        MSG_REGISTER_PRODUCT_DESC: 'Se Registro Exitosamente el Producto: ##1',
        MSG_UPDATE_PRODUCT_TITLE: 'Actualizar Producto',
        MSG_UPDATE_PRODUCT_DESC: 'Se Actualizó Exitosamente el Producto: ##1',
    },
    NEW_ORDER: {
        BUTTON_BACK: 'Regresar',
        BUTTON_GUARDAR: 'Guardar',
        URL_PRODUCTION_ORDER_LIST: INTERNAL_ROUTES.KATIA_PRODUCTION_LIST_ALL_ORDER,
        OBSERVACIONES: 'Observaciones',
        MSG_REGISTER_PRODUCTION_ORDER_TITLE: 'Crear Orden de Producción',
        MSG_REGISTER_PRODUCTION_ORDER_DESC: 'Se Registro Exitosamente la Orden de Producción: ##1',
    },
    VIEW: {
        MSG_GET_PRODUCT_TITLE: 'Información Producto',
        MSG_GET_PRODUCT_DESC: 'No se encontro información para el producto Seleccionado',
        MSG_GET_ORDER_TITLE: 'Información Orden Producción',
        MSG_GET_ORDER_DESC: 'No se encontro información para la Orden de Producción Seleccionado',
    },
    UPDATE_ORDER: {
      BUTTON_BACK: 'Regresar',
      BUTTON_GUARDAR: 'Actualizar',
      URL_PRODUCTION_ORDER_LIST: INTERNAL_ROUTES.KATIA_PRODUCTION_LIST_ALL_ORDER,
      OBSERVACIONES: 'Observaciones',
      MSG_UPDATE_PRODUCTION_ORDER_TITLE: 'Actualizar Orden de Producción',
      MSG_UPDATE_PRODUCTION_ORDER_DESC: 'Se Actualizó Exitosamente la Orden de Producción: ##1',
  },


}
