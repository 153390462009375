import { INTERNAL_ROUTES } from "@data/constants/routes";

export const COMERCIAL_CONSTANTS = {
    TITLES: {
        COMERCIAL: 'Comercial',
        PROVEEDOR_INT: 'Proveedor internacional',
        CLIENTE_INT: 'Cliente internacional',
        PROVEEDOR_INT_NEW: 'Agregar proveedor',
        PROVEEDOR_INT_EDIT: 'Editar proveedor',
        CLIENTE_INT_NEW: 'Agregar cliente',
        CLIENTE_INT_EDIT: 'Editar cliente',
        PROFORMA: 'Proforma',
        PROFORMA_EDIT: 'Editar proforma',
        PROFORMA_NEW: 'Nueva proforma',
        EMPAQUES: 'Empaques',
        EMPAQUES_EDIT: 'Editar empaques',
        EMPAQUES_NEW: 'Agregar empaque',
        EMPAQUES_LIST: 'Lista de empaques',
        EMPAQUES_NEW_LIST: 'Agregar lista de empaques',
        SEGUIMIENTO: 'Registro de compra',
        SEGUIMIENTO_NEW: 'Agregar pedido',
        MSG_CONFIRM_ACTIVATE_PROVEEDOR : '¿Esta seguro de Activar el proveedor ## ?',
        MSG_CONFIRM_INACTIVATE_PROVEEDOR : '¿Esta seguro de Inactivar la proveedor ## ?',
        MSG_CONFIRM_ACTIVATE_CLIENTE : '¿Esta seguro de Activar el cliente ## ?',
        MSG_CONFIRM_INACTIVATE_CLIENTE : '¿Esta seguro de Inactivar el cliente ## ?',
        
        
    },
    LIST: {

        BUTTON_NEW: 'Ingresar Producto',        
        URL_PROVEEDOR_NEW: INTERNAL_ROUTES.KATIA_COMERCIAL_PROVEEDOR_INT_NEW,  
        URL_PROVEEDOR_EDIT: INTERNAL_ROUTES.KATIA_COMERCIAL_PROVEEDOR_INT_EDIT,  
        URL_CLIENTE_NEW: INTERNAL_ROUTES.KATIA_COMERCIAL_CLIENTE_INT_NEW, 
        URL_CLIENTE_EDIT: INTERNAL_ROUTES.KATIA_COMERCIAL_CLIENTE_INT_EDIT, 
        URL_PROFORMA_NEW: INTERNAL_ROUTES.KATIA_COMERCIAL_PROFORMA_NEW,       
        SEARCH_PRODUCTS: 'Buscar Productos',
    },
    STATUS: {
        ACTIVE: 1,   
        MSG_ACTIVE: 'Activo',       
        INACTIVE: 2, 
        MSG_INACTIVE: 'Inactivo',
    },
    NEW: {
        BUTTON_BACK: 'Regresar',
        BUTTON_GUARDAR: 'Guardar',
        URL_PRODUCT_LIST: INTERNAL_ROUTES.KATIA_PRODUCTION_LIST_ALL_PRODUCT_SHEETS,
        MSG_REGISTER_PRODUCT_TITLE: 'Crear Producto',
        MSG_REGISTER_PRODUCT_DESC: 'Se Registro Exitosamente el Producto: ##1',
    }
}