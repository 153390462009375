import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_ROUTES } from "@data/constants/routes";
import { AuthService } from "@data/services/api/auth.service";
import { Observable } from "rxjs";


@Injectable()
export class JWTInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService
    ) { }
    /**
     * Metodo encargado de interceptar todos los servicios que se consuman via HTTP
     * Obtiene el token de JWT generado en el backend, para asi poderlo enviar
     * nuevamente a los otros servicios que lo soliciten
     * @param req 
     * @param next 
     * @returns 
     */
     intercept(
        req: HttpRequest<any>, 
        next: HttpHandler
        ): Observable<HttpEvent<any>> {
            /** Obtiene la informacion del usuario autenticado */
            const currenteUser = this.authService.getUser;
            /** Valida si existe el usuario y existe el token enviado desde backend */
            const isAuthenticated = currenteUser && currenteUser.token;
            /** Si el usuario esta autenticado, envia el token generado en el login, para poder utilizar los servicios */
            if(isAuthenticated)
            req = req.clone({
                setHeaders: {
                    Authorization: currenteUser.token
                    //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJhZnRlYzM2MEpXVCIsInN1YiI6InBlYW5AZ21haWwuY29tIiwiYXV0aG9yaXRpZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTY2ODA4Nzk0OCwiZXhwIjoxNjY4MDg5NzQ4fQ.CH197w41yNkPblOi25R6GUZPpgPZSbwH-2j10PguUMuRlpNDtHh6hkYc4WEJHXHOUq9tOnLfq75CUdkFqi2vhQ`
                }
            });
            return next.handle(req);
        }
}