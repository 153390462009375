import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ERRORS_VALIDATIONS_CONSTS, GENERAL_CONSTANTS } from '@data/constants';
import { API_ROUTES, INTERNAL_ROUTES } from '@data/constants/routes';
import { IApiUser } from '@data/interfaces';
import { ApiClass } from '@data/schema/ApiClass.class';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ApiClass {
  // Variable de tipo Observable con los datos del usuario
  public currentUser: BehaviorSubject<IApiUser>;
  //Variable para almacenar en el Local Storage la informacion del usuario autenticado
  public nameUserLocalStorage = 'currentUserDesingCode';

  constructor(
    http: HttpClient,
    private router: Router
  ) {
    super(http);
    this.currentUser = new BehaviorSubject(null);
    // Definir el valor inicial del BehaviorSubject en formato JSON
    if (localStorage.getItem(this.nameUserLocalStorage) !== 'undefined') {
      this.currentUser = new BehaviorSubject(
        JSON.parse(localStorage.getItem(this.nameUserLocalStorage))
      );
    }

  }

  /**
   * Metodo para acceder a la informacion dle usuario autenticado
   */
  get getUser(): IApiUser {
    return this.currentUser.value;
  }

  login(
    data: {
      username: string;
      password: string;
    }
  ): Observable<any> {
    //const response = { error: true, msg: ERRORS_VALIDATIONS_CONSTS.LOGIN.ERROR.USER_NO_FOUND, data: null };    
    //Invocar al servicio de autenticacion
    return this.http.post<IApiUser>(API_ROUTES.AUTH.LOGIN, data)
      .pipe(
        map(r => {
          // Validar si no retorno un error al momento de autenticar, para redireccionar al panel principal
          if (r) {
            if (r.authUserComp.length > 1) {
              //Se redirecciona a la pantalla de seleccion de empresa
              console.log(INTERNAL_ROUTES.AUTH_USER_COMPANY)
              this.setCurrentuser(r, true);
              this.router.navigateByUrl(INTERNAL_ROUTES.AUTH_USER_COMPANY);
            }
            else if (r.authUserComp.length == 0 && r.isRoot == GENERAL_CONSTANTS.STATUS.IS_ROOT) {
              //Se redirecciona al home, como usuario super-admin
              this.setCurrentuser(r, true);
              this.router.navigateByUrl(INTERNAL_ROUTES.PANEL_USER_HOME);
            }
            else if (r.authUserComp.length == 1 && r.authUserComp[0].status == GENERAL_CONSTANTS.STATUS.ACTIVE) {
              //Se redirecciona al home, como usuario activo en la empresa y como empresa activa
              this.setCurrentuser(r, true);
              this.router.navigateByUrl(INTERNAL_ROUTES.PANEL_USER_HOME);
            }
            else if (r.authUserComp.length == 1 && r.authUserComp[0].status == GENERAL_CONSTANTS.STATUS.INACTIVE && r.isRoot == GENERAL_CONSTANTS.STATUS.IS_ROOT) {
              //Se elimina la empresa para el usuario, ya que el usuario esta inactivo, pero se loguea como usuario super-admin
              r.authUserComp = null;
              this.setCurrentuser(r, true);
              this.router.navigateByUrl(INTERNAL_ROUTES.PANEL_USER_HOME);
            }
            else if (r.authUserComp.length == 1 && r.authUserComp[0].status == GENERAL_CONSTANTS.STATUS.INACTIVE) {
              // Se muestra mensaje de acceso no autorizado para usuario inactivo
              this.logout();
              const navigationExtras: NavigationExtras = { state: { validation: ERRORS_VALIDATIONS_CONSTS.LOGIN.ACCESS_RULES.USER_INACTIVE_IN_COMPANY } };
              this.router.navigate([INTERNAL_ROUTES.SERVER_ERROR_401], navigationExtras);
            }
            else if (r.authUserComp.length == 0 && r.authUsrRol[0].authRoles.rolId == parseInt(GENERAL_CONSTANTS.ROLES.ADMIN)) {
              //Se redirecciona al home, como usuario super-admin
              this.setCurrentuser(r, true);
              this.router.navigateByUrl(INTERNAL_ROUTES.PANEL_USER_HOME);
            }
            else {
              // Se muestra mensaje de acceso no autorizado general
              this.logout();
              const navigationExtras: NavigationExtras = { state: { validation: ERRORS_VALIDATIONS_CONSTS.LOGIN.ACCESS_RULES.USER_UNASSIGNED_A_COMPANY } };
              this.router.navigate([INTERNAL_ROUTES.SERVER_ERROR_401], navigationExtras);
            }
          }
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
   * Metodo encargado de asingar el usuario a autenticar en el Lolcal Storage
   * @param r 
   * @param authenticated 
   */
  setCurrentuser(r, authenticated: boolean) {
    //Asingar la respuesta del API al response del proceso
    //console.log('response + msg: ' , r);
    //console.log('response + error: ' , r.data);
    //console.log('response -> data: ' , r);
    r.authenticated = authenticated;//Evitar la redireccion al componente de app-user-unauthorized (Cuando no esta autenticado al 100%)
    this.setuserToLocalStorage(r);//Almacenar el usuario en el local storage          
    //this.currentUser.next(r.data);//Asignar al Behavios el usuario
    this.currentUser = new BehaviorSubject(JSON.parse(localStorage.getItem(this.nameUserLocalStorage)));//Asignar al Behavios el usuario
  }


  /**
   * Metodo encargado de realizar 
   */
  logout() {
    localStorage.removeItem(this.nameUserLocalStorage);
    this.currentUser.next(null!);
    localStorage.removeItem('navigationRoutes');
    localStorage.clear();
    //alert('Entro logout');
    //alert('currentUser:' + this.currentUser.value);
    this.router.navigateByUrl(INTERNAL_ROUTES.AUTH_LOGIN);
    //Eliminar la configuracion del color de los menus
    $("html").attr("class", "light-theme");
  }

  /**
   * Asignar la informacion del usuario autenticado al local Storage
   * @param user 
   */
  setuserToLocalStorage(user: IApiUser) {
    console.log("User", user);
    localStorage.setItem(this.nameUserLocalStorage, JSON.stringify(user));
    //console.log (localStorage.getItem(this.nameUserLocalStorage));
  }

  isUserLoggedIn() {
    return !(this.getUser === null)
  }
}
