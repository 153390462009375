import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { COMPANY_CONSTANTS } from '@data/constants';
import { EnumServiceResponseCodes } from '@data/enums/enum-service-response-code';
import { IApiCompany, IApiModule, IApiRole, IApiDragAndDrop, IApiUser } from '@data/interfaces';
import { CompanyService } from '@data/services/api/company.service';
import { ModuleService } from '@data/services/api/module.service';
import { RoleService } from '@data/services/api/role.service';
import { DropListEnum } from './droplist.enum';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss']
})
export class DragAndDropComponent implements OnChanges {

  public data = COMPANY_CONSTANTS.COMPANY_ASSIGN_MODULE;

  //Input con el tipo de DragAndDrop a generar, en caso de ser Default no muestra nada
  @Input() typeDropList: DropListEnum = DropListEnum.DEFAULT;

  //Input con la empresa seleccionada
  @Input() companySelected: IApiCompany;

  //Input con el modulo seleccionado
  @Input() moduleSelected: IApiModule;

  //Input con el rol seleccionado
  @Input() roleSelected: IApiRole;

  //Input con el rol seleccionado
  @Input() userSelected: IApiUser;

  //Input con el titulo de los seleccionados
  @Input() nameItemsSelected: string;

  //Input con el titulo de los disponibles
  @Input() nameItemsAvailables: string;

  //Output Generico para retornar cualquier dato 
  @Output() dropListEvent = new EventEmitter<any>();

  public dropListEnum = DropListEnum;

  //Muestra las listas para mover 
  dropListAvailable: any[] = [];
  dropListSelected: any[] = [];

  dropListComplete: IApiDragAndDrop;

  //Lista de los modulos en compañia (guarda la informacion del back)
  modulesInCompany: any[] = [];
  submodulesByRole: any[] = [];

  //Lista de los modulos en compañia (guarda la informacion del back)
  rolesInCompany: any[] = [];

  constructor(
    private moduleService: ModuleService,
    private companyService: CompanyService,
    private rolesService: RoleService,
  ) {
    this.dropListAvailable = [];
    this.dropListSelected = [];
  }

  ngOnChanges(): void {
    switch (this.typeDropList) {
      case this.dropListEnum.PARENT_MODULES:
        this.getParentModulesByCompany();
        break;
      case this.dropListEnum.CHILD_MODULES_BY_ROLE:
        this.getChildModulesByRole();
        break;
      case this.dropListEnum.ROLES:
        this.getRolesByUser();
        break;
      default:
        break;
    }

  }

  getParentModulesByCompany() {
    this.dropListSelected = [];
    this.dropListAvailable = [];
    if (this.companySelected !== undefined && this.companySelected !== null) {
      this.companyService.getModulesByCompany(this.companySelected.companyId).subscribe(r => {
        this.modulesInCompany = r.authModulesByCompany;
        if (this.modulesInCompany != undefined && this.modulesInCompany.length > 0) {
          this.dropListSelected = this.modulesInCompany.map(x => {
            return x.authModules;
          })
        }
        this.moduleService.getAllParentModules().subscribe(r => {
          if (r.code === EnumServiceResponseCodes.success) {
            this.dropListAvailable = r.authModules.
              filter((allModule) => !this.dropListSelected.
                some((selectedModule) => selectedModule.modId === allModule.modId));
          }
        });
      });
    }

  }

  getChildModulesByRole() {
    this.dropListSelected = [];
    this.dropListAvailable = [];
    if (this.moduleSelected !== undefined && this.moduleSelected !== null) {
      this.moduleService.getAllChildModulesByRole(this.roleSelected.rolId).subscribe(r => {
        if (r.code === EnumServiceResponseCodes.success) {
          if (r.authModules.length > 0) {
            console.log ("r.authModules.length", r.authModules.length)
            console.log ("r.authModules", r.authModules)
            this.dropListSelected = r.authModules.filter(module =>{
              return this.moduleSelected.modId === module.modParentId;
            });
            console.log ("this.dropListSelected", this.dropListSelected.length)
          }
        }
        this.moduleService.getAllChildModulesByParentId(this.moduleSelected.modId).subscribe(r => {
          if (r.code === EnumServiceResponseCodes.success) {
            if (r.authModules.length > 0) {
              this.dropListAvailable = r.authModules.
                filter((allModule) => !this.dropListSelected.
                  some((selectedModule) => selectedModule.modId === allModule.modId));
            }
          }
        });
      });
    }

  }

  getRolesByUser() {
    this.dropListSelected = [];
    this.dropListAvailable = [];
    if (this.userSelected !== undefined && this.userSelected !== null) {
      //this.rolesService.findRolesByUser(this.userSelected.userId).subscribe(r => {
        this.rolesInCompany = this.userSelected.authUsrRol.map (roles =>{
          return roles.authRoles;
        })
        
        console.log("this.rolesInCompany", this.rolesInCompany)
        if (this.rolesInCompany != undefined && this.rolesInCompany.length > 0) {
          console.log("Entro a ")
          this.dropListSelected = this.rolesInCompany;
        }
        this.rolesService.findRolesByCompanyId(this.userSelected.authUserComp[0].authCompany.companyId).subscribe(r => {
          if (r.code === EnumServiceResponseCodes.success) {
            this.dropListAvailable = r.authRoles.
              filter((allRoles) => !this.dropListSelected.
                some((selectedRole) => selectedRole.rolId === allRoles.rolId));
          }
        });
      //});
    }

  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    this.dropListComplete = {} as IApiDragAndDrop;
    this.dropListComplete.itemsAvailable = this.dropListAvailable;
    this.dropListComplete.itemsSelected = this.dropListSelected;
    this.dropListEvent.emit(this.dropListComplete);
  }

}
