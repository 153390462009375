<div class="alert alert-danger border-0 bg-danger alert-dismissible fade show py-2" >
    <div class="d-flex align-items-center">
        <div class="font-35 text-white"><i class='bx bxs-error-circle'></i>
        </div>
        <div class="ms-3">
            <div class="text-white text-center">{{message}}</div>
        </div>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>
