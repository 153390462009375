import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { API_ROUTES } from "@data/constants/routes";
import { AuthService } from "@data/services/api/auth.service";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private router: Router,
        private http: HttpClient,
        private authService: AuthService
    ) { }
    /**
     * Metodo encargado de interceptar los errores 401.403 y 404 del HTTP
     * Segun las respuesta de determinada API
     * @param req 
     * @param next 
     * @returns 
     */
    intercept(
        req: HttpRequest<any>, 
        next: HttpHandler
        ): Observable<HttpEvent<any>> {
            return next.handle(req).pipe(
                catchError((err) => {
                    console.log('INTERCEPTOR' ,err);
                    if(
                        [403, 404].indexOf(err.status) !== -1 && req.url !== API_ROUTES.AUTH.LOGIN
                    ){
                        this.router.navigateByUrl('/' + err.status);
                    }else if(err instanceof HttpErrorResponse && err.status === 401){
                        return this.handle401Error(req, next);
                    }
                    return throwError(err);
                })
            );
        }


    private handleAuthError() {
        sessionStorage.clear();
        this.router.navigateByUrl('home');
    }
        
    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            const currenteUser = this.authService.getUser;
            if (currenteUser.token) {
                const options = {
                    headers: new HttpHeaders({
                        'isRefreshToken': 'true',
                        Authorization: `${currenteUser.token}`
                    }),
                };

                return this.http.get<any>(API_ROUTES.AUTH.REFRESH_TOKEN, options).pipe(
                    switchMap((responseRefresh: any) => {
                        this.isRefreshing = false;
                        const newToken = `Bearer ${responseRefresh.token}`;
                        currenteUser.token = newToken;
                        this.authService.setuserToLocalStorage(currenteUser);
                        //sessionStorage.setItem('token', newToken)
                        this.refreshTokenSubject.next(newToken);

                        return next.handle(this.addTokenHeader(request, newToken));
                    }),
                    catchError((err) => {
                        this.isRefreshing = false;
                        this.handleAuthError();
                        return throwError(err);
                    })
                );
            }
        }
        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addTokenHeader(request, token)))
        );
    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        /* for Spring Boot back-end */
        // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
        /* for Node.js Express back-end */
        return request.clone({ headers: request.headers.set('Authorization', token) });
    }
}