import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiClass } from "@data/schema/ApiClass.class";
import { API_ROUTES, INTERNAL_ROUTES } from "@data/constants/routes";
import { IApiUser, IApiUserResponse, IApiAuthUserComp, IApiCompany, IApiAuthUsrRol } from "@data/interfaces";
import { Router } from "@angular/router";
import { GENERAL_CONSTANTS } from "@data/constants";


@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiClass {

  constructor(http: HttpClient,
    private router: Router) {
    super(http);
  }

  /**
   * Metodo encargado de consultar el listado de todos los usuarios registrados en el sistema
   * @returns 
   */
  getAllUsers(): Observable<any> {
    const response = { error: false, msg: '', data: null };
    return this.http.get<IApiUserResponse>(API_ROUTES.USERS.LIST)
      .pipe(
        map(r => {
          response.data = r.authUsers;
          return response;
        }),
        catchError(this.error)
      );
  }

  /**
   * Metodo encargado de consultar la informacion de un usuario por medio del id del usuario de una compañia especifica
   * @param userId id del usuario
   * @param companyId id de la compañia
   * @returns iApiUserResponse Objeto con la informacion del usuario
   */
  findByUserId(userId: number, companyId: number): Observable<any> {
    return this.http.get<IApiUserResponse>(API_ROUTES.USERS.FIND_USER_BY_ID + '/' + userId + '/companyId/' + companyId)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
   * Metodo encargado de consultar el listado de todos los usuarios registrados en el sistema
   * @returns objeto con el listado de usuarios
   */
  getAllUsersByCompany(companyId: number): Observable<any> {
    if (companyId > 0) {
      const response = { error: false, msg: '', data: null };
      return this.http.get<IApiUserResponse>(API_ROUTES.USERS.LIST_BY_COMPANY + '/' + companyId)
        .pipe(
          map(r => {
            response.data = r.authUsers;
            return response;
          }),
          catchError(this.error)
        );
    }
    else {
      return new Observable<HttpEvent<any>>();
    }

  }

  /**
   * Create SuperAdmin 
   */
  createAdministrator(data: {
    firstName: string; lastName: string; username: string;
    userpass: string; userEmail: string; authUserComp: []; isRoot: boolean;
  }): Observable<any> {
    //Invocar al servicio de creacion de usuario
    return this.http.post<IApiUser>(API_ROUTES.USERS.NEW, data)
      .pipe(map(r => {
        console.log('response + msg: ', r);
        return r;
      }),
        catchError(this.error)
      );
  }

  /**
   * Metodo encargado de crear un usuario para una compañia especifica
   * @param data objeto con los valores para la creacion del usuario
   * @returns iApiUserResponse objeto con la respuesta de la transaccion
   */
  createUser(data: any): Observable<any> {
    //Invocar al servicio de creacion de usuario
    return this.http.post<IApiUserResponse>(API_ROUTES.USERS.NEW, data)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }
  /**
   * Metodo encargado de actualizar un usuario para una compañia especifica
   * @param data informacion del usuario que se desea actualizar
   * @returns 
   */
  updateUser(data: any): Observable<any> {
    //Invocar al servicio de creacion de usuario
    return this.http.put<IApiUserResponse>(API_ROUTES.USERS.UPDATE, data)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
   * Metodo encargado de actualizar el estado de un usuario para una compañia especifica
   * @param user objeto con el estado y los datos de usuario y compañia a actualizar
   * @returns iApiUserResponse objeto con la respuesta de la transaccion
   */
  updateStatusUser(user: IApiUser): Observable<any> {
    const userUpdate: IApiAuthUserComp = {} as IApiAuthUserComp;
    userUpdate.userCompId = user.authUserComp[0].userCompId;
    userUpdate.authCompany = {} as IApiCompany;
    userUpdate.authCompany.companyId = user.authUserComp[0].authCompany.companyId;
    userUpdate.status = (user.authUserComp[0].status == GENERAL_CONSTANTS.STATUS.ACTIVE ? GENERAL_CONSTANTS.STATUS.INACTIVE : GENERAL_CONSTANTS.STATUS.ACTIVE);//Asignar el cambio de estado a la inversa
    //console.log('userUpdate ' , userUpdate);

    //Invocar al servicio de actualizacion del estado de un usuario
    return this.http.put<IApiUser>(API_ROUTES.USERS.UPDATE_STATUS + '/' + user.userId, userUpdate)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
   * Metodo encargado de resetear el password de un usuario especifico
   * @param userEmail email al cual se le desea reestablecer la contraseña
   * @param companyId  id de la compañia a la que pertenece el usuario
   * @returns objeto con la respuesta de la transaccion
   */
  resetPassword(userEmail: string, companyId: number): Observable<any> {
    //Invocar al servicio de actualizacion del estado de un usuario
    return this.http.put<IApiUser>(API_ROUTES.USERS.RESET_PASSWORD + '/' + userEmail + '/companyId/' + companyId, null)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }


  /**
   * Metodo encargado de resetear el password de un usuario especifico, sin token
   * @param email 
   * @returns objeto con la respuesta de la transaccion
   */
  forgotPassword(email: string): Observable<any> {
    //Invocar reestablecer password
    return this.http.put<IApiUser>(API_ROUTES.USERS.FORGOT_PASSWORD + '/' + email, null)
      .pipe(
        map(r => {
          console.log('forgotPassword - r -> ', r);
          return r;
        }),
        catchError(this.error)
      );
  }

  putNewPasword(data: any): Observable<any> {
    //Asignacion de parametros adicionales
    data.userpass = btoa(data.currentPassword);
    data.newPass = btoa(data.newPassword);
    data.updPass = true;
    console.log('form: ', data);
    //Invocar al servicio de actualizacion de contraseña
    return this.http.put<IApiUser>(API_ROUTES.USERS.PUT_NET_PASSWORD, data)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  /**
   * Metodo encargado de consultar la informacion de un usuario por medio del documento del usuario
   * @param userDocument documento del usuario
   * @returns iApiUserResponse Objeto con la informacion del usuario
   */
  findUserByDocument(userDocument: number): Observable<any> {
    return this.http.get<IApiUserResponse>(API_ROUTES.USERS.FIND_USER_BY_DOCUMENT + '/' + userDocument)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }


  /**
   * Metodo encargado de asignar un usuario a una compania especifica
   * @param companyId id de la compania
   * @param userId id del usuario
   * @returns 
   */
  addCompanyToUser(companyId: number, userId: number): Observable<any> {
    //Invocar al servicio de creacion de usuario
    return this.http.post<IApiUserResponse>(API_ROUTES.USERS.ADD_COMPANY_TO_USER + '/' + userId + '/company/' + companyId + '/add', null)
      .pipe(
        map(r => {
          return r;
        }),
        catchError(this.error)
      );
  }

  
}
