import { Component, OnInit } from '@angular/core';
import { INTERNAL_ROUTES } from '@data/constants/routes';
import { USER_CONSTANTS } from '@data/constants';
import { AuthService } from '@data/services/api/auth.service';
import { IApiUser } from '@data/interfaces';


@Component({
  selector: 'app-header-user, [app-header-user]',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.css']
})
export class HeaderUserComponent implements OnInit {

  public URL_UPDATE_PASSWORD: string = INTERNAL_ROUTES.USER_GENERAL_UPDATE_PASSWORD;
  public URL_USER_PROFILE: string = INTERNAL_ROUTES.USER_GENERAL_PROFILE;
  public data = USER_CONSTANTS.TITLES;
  public userCurrent: IApiUser;
  
  constructor(
    private authService: AuthService
  ) { 
    //Inicializar variables
    this.userCurrent = {} as IApiUser;
  }

  ngOnInit(): void {
    this.userCurrent = this.authService.getUser;
  }

  /**
   * Metodo encargado de invocar el servicio para le cierre de sesion
   */
  logout()
  {
    this.authService.logout();
  }

}
