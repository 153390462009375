import { Component, OnInit } from '@angular/core';
import { NAVIGATION_ROUTES } from './navigation-routes.config';

import * as $ from 'jquery';
import { MatTableDataSource } from '@angular/material/table';
import { ModuleService } from '@data/services/api/module.service';
import { AuthService } from '@data/services/api/auth.service';
import { IApiCompany, IApiUser } from '@data/interfaces';
import { NavigationInfo } from './inavigation.metadata';
import { GENERAL_CONSTANTS } from '@data/constants';
import { EnumServiceResponseCodes } from '@data/enums/enum-service-response-code';

@Component({
  selector: 'app-navigation, [app-navigation]',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  public menuItems: any[];
  public menuItemsRest: any[];
  public user: IApiUser;
  public company: IApiCompany;

  constructor(private moduleService: ModuleService,
    private authService: AuthService) {

  }

  ngOnInit() {
    $.getScript('./assets/js/app-sidebar.js');
    this.getNavigation();


  }

  getNavigation() {

    this.user = this.authService.getUser;
    console.log("User is Root =" + this.user.isRoot)
    if (this.user.isRoot) {
      this.menuItems = NAVIGATION_ROUTES.filter(menuItem => menuItem);
      localStorage.setItem('navigationRoutes', JSON.stringify(this.menuItems));
    
    } else if (this.user.authUsrRol[0].authRoles.rolId === parseInt(GENERAL_CONSTANTS.ROLES.ADMIN)) {

      if (localStorage.getItem('navigationRoutes') !== undefined && localStorage.getItem('navigationRoutes') !== null) {
        console.log("Navigation Routes from LocalStorage")
        this.menuItems = JSON.parse(localStorage.getItem('navigationRoutes'));
        console.log("Navigation Routes from LocalStorage", this.menuItems)
      } else {
        let index = NAVIGATION_ROUTES.findIndex(x =>
          x.title === GENERAL_CONSTANTS.MENU.SUPERADMIN
        );
        console.log("index",index)
        // NAVIGATION_ROUTES.splice(index, 1);
        this.menuItems = NAVIGATION_ROUTES.filter(menuItem => menuItem);
        this.menuItems.splice(index, 1);

        localStorage.removeItem('navigationRoutes');
        localStorage.setItem('navigationRoutes', JSON.stringify(this.menuItems));
      }
    } else {
      if (localStorage.getItem('navigationRoutes') !== undefined && localStorage.getItem('navigationRoutes') !== null) {
        console.log("Navigation Routes from LocalStorage")
        this.menuItems = JSON.parse(localStorage.getItem('navigationRoutes'));
      } else {
        console.log("Navigation Routes from Services")
        this.moduleService.getModulesAndSudModulesByUserAndCompany(this.user, this.user.authUserComp[0].authCompany).subscribe(r => {
          if (r.code === EnumServiceResponseCodes.success) {
            this.menuItemsRest = r.modulesList;
            console.log("NAVIGATION_ROUTES", NAVIGATION_ROUTES)

            let routesItems = [] as NavigationInfo[];
            for (let routes of NAVIGATION_ROUTES) {
              for (let menuItem of this.menuItemsRest) {
                if (menuItem.submodules.length > 0) {
                  let routesFound = {} as NavigationInfo;
                  if (menuItem.modName === routes.title) {
                    console.log("Menus Iguales")
                    let submodulesSelected = {} as NavigationInfo;
                    submodulesSelected.submenu = [];
                    console.log("routes", routes)
                    console.log("menuItem", menuItem)
                    for (let submenu of routes.submenu) {
                      for (let submodules of menuItem.submodules) {
                        console.log("submodules", submodules)
                        console.log("submodules.subModName=", submodules.subModName)
                        console.log("submenu.title=", submenu.title)
                        if (submodules.subModName === submenu.title) {
                          console.log("submenu.title PUSH=", submenu)
                          submodulesSelected.submenu.push(submenu);
                        }
                      }
                    }
                    routesFound = routes;
                    routesFound.submenu = submodulesSelected.submenu;
                    routesItems.push(routesFound);
                    break;
                  }
                }
              }
            }

            console.log("routesItems", routesItems)

            // NAVIGATION_ROUTES.forEach(routes => {
            //   let moduleFound = false;
            //   let routesFound = {} as NavigationInfo;
            //   const submodulesSelected = {} as NavigationInfo;
            //   submodulesSelected.submenu = [];
            //   this.menuItemsRest.forEach(menuItem => {
            //     console.log("menuItem.modName=", menuItem.modName)
            //     console.log("routes.title=", routes.title)
            //     if (menuItem.modName === routes.title) {
            //       moduleFound = true;
            //       routesFound = routes;
            //       console.log("menuItem.modName === routes.title=", true)
            //       console.log("menuItem.modName === routes.title=", true)
            //       routes.submenu.forEach((submenu) => {
            //         console.log("submenu", submenu)
            //         console.log("menuItem", menuItem)
            //         menuItem.submodules.forEach((submodules) => {
            //           console.log("submodules", submodules)
            //           console.log("submodules.subModName=", submodules.subModName)
            //           console.log("submenu.title=", submenu.title)
            //           if (submodules.subModName === submenu.title) {
            //             console.log("submenu.title PUSH=", submenu)
            //             submodulesSelected.submenu.push(submenu);
            //             break();
            //           }
            //         });
            //       });
            //     }

            //   })

            //   console.log("Acaba el segundo for")
            //   console.log("routesFound", routesFound)
            //   routesFound.submenu = submodulesSelected.submenu;
            //   console.log("moduleFound", moduleFound)
            //   if (moduleFound && routesFound.submenu.length > 0) {
            //     this.menuItems.push(routesFound);
            //   }
            // if (!moduleFound || (moduleFound && routes.submenu.length==0)) {
            //   let index = NAVIGATION_ROUTES.findIndex(x =>
            //     x.title === routes.title
            //   );
            //   console.log("index", index)
            //   NAVIGATION_ROUTES.splice(index, 1);
            // }
            // });

            console.log("NAVIGATION_ROUTES=", NAVIGATION_ROUTES)
            console.log("this.routesItems=", routesItems)
            this.menuItems = routesItems;
            localStorage.setItem('navigationRoutes', JSON.stringify(routesItems));
          }
        });
      }
    }
  }


}
